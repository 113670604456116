import { ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  children: ReactNode;
  type?: 'info' | 'warning';
};
export default function InformationCard({ children, type = 'info' }: Props) {
  return (
    <InformationCardWrapper $type={type}>
      {children}
    </InformationCardWrapper>
  );
}

const InformationCardWrapper = styled.div<{ $type: 'info' | 'warning' }>`
  background-color: ${({ theme, $type }) => $type === 'warning' ? theme.colorYellowLight : theme.colorBlueBackground};
  color: ${({ theme, $type }) => $type === 'warning' ? theme.colorWarning : theme.colorBlack};
  padding: 15px;
  border-radius: 3px;
`;
