import { useEffect } from 'react';

const SITEBAR_HEIGHT = 50;

export function anchorLinkOffset(): void {
  window.addEventListener('hashchange', () => {
    if (location?.hash?.length !== 0) {
      window.scrollTo(window.scrollX, window.scrollY - SITEBAR_HEIGHT - 5);
    }
  });
}

export const scrollToElementId = (id: string, timeout?: number): void => {
  const elementToScroll = document.getElementById(id);
  if (elementToScroll) {
    setTimeout(() => {
      window.scrollTo({
        behavior: 'smooth',
        top: elementToScroll.offsetTop - 60,
      });
    }, timeout ?? 500);
  }
};

export const useScrollToElementIdIfUrlHash = (anchor: string, timeout?: number): void => {
  useEffect(() => {
    const fragment = window.location.hash?.replace('#', '');
    if (fragment === anchor) scrollToElementId(fragment, timeout);
  }, []); // eslint-disable-line
};
