// @ts-strict-ignore
import { NextIncomingMessage } from 'next/dist/server/request-meta';

export function getUserAgent(req?: NextIncomingMessage): string {
  if (req) {
    return req?.headers?.['user-agent'];
  } else if (typeof window !== 'undefined') {
    return window?.navigator?.userAgent;
  } else {
    return '';
  }
}
