// @ts-strict-ignore
import { useCallback, useEffect, useState } from 'react';
import TextInput from 'src/components/inputs/textInput';
import { isUsernameAvailable } from 'src/modules/shared/auth/actions';
import i18n from 'src/utils/translate';
import { required, minLength, maxLength, username } from 'src/utils/validators';
import { usernameMinLength, usernameMaxLength, usernameErrors } from 'src/constants/validationErrors';
import useDebouncedState from 'src/utils/hooks/useDebouncedState';

const validators = [
  required(usernameErrors.required),
  minLength(usernameMinLength, usernameErrors.min),
  maxLength(usernameMaxLength, usernameErrors.max),
  username(),
];

type Props = {
  placeholder?: string;
  setUsername: (username: string) => void;
}

export default function ChooseUsernameForm({
  placeholder = i18n.t('Choose your username'),
  setUsername,
}: Props) {
  const [value, setValue] = useDebouncedState('');
  const errors = useValidators(value, setUsername);

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.target.blur();
    }
  };

  return (
    <TextInput
      dataTestId="signup-survey-username"
      id="username"
      placeholder={placeholder}
      type="text"
      validationErrors={errors}
      onChange={(e) => setValue(e.target.value)}
      onKeyPress={onKeyPress}
    />
  );
}
const useValidators = (value: string, setUsername): string[] => {
  const [errors, setErrors] = useState<string[]>([]);

  useEffect(() => {
    if (!value) return;
    setErrors(validators.map((validator) => validator(value)).filter(Boolean));
  }, [value, setErrors]);

  const checkAvailability = useCallback(async () => {
    if (await isUsernameAvailable(value)) {
      setUsername(value);
    } else {
      setErrors([usernameErrors.notAvailable]);
      setUsername(null);
    }
  }, [value, setErrors, setUsername]);

  const hasError = errors?.[0];

  useEffect(() => {
    if (!hasError && value !== '') checkAvailability();
  }, [hasError, value, checkAvailability]);

  return errors;
};
