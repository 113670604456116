const NextI18Next = require('next-i18next').default;
const path = require('path');

module.exports = new NextI18Next({
  otherLanguages: ['es', 'pt'],
  detection: {
    lookupCookie: 'huLang', // plugin creates and reads this cookie
    caches: ['cookie'],
  },
  load: 'languageOnly',
  keySeparator: false, // allow keys to be phrases having '.'
  nsSeparator: false, // allow keys to be phrases having ':'
  pluralSeparator: false, // allow keys to be phrases having '_'
  contextSeparator: false, // allow keys to be phrases having '_'
  // if any path token starts with these strings, i18n won't be loaded.
  // (this is intentional on the part of the library so it doesn't get loaded
  // for static files, just for page routes.)
  // however this means that any _post titles_ that start with these strings will
  // cause an error because the post page relies on i18n being there.
  // so we need to make sure that the strings here end with a slash to make them
  // harder to match. we may need to make this more restrictive again, or make
  // a PR to the library to make a better fix.
  ignoreRoutes: ['/_next', '/public/static/', '/styles', '/tests'],
  localePath: path.resolve('public/static/locales'),
});
