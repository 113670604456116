import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api, { createAuthenticatedApiInstance } from 'src/utils/api';
import { Integration } from 'src/modules/community/communityMetrics/types';

export const initialState: Integration = {
  gaTrackingId: null,
  status: 'idle',
};

export const integrationSlice = createSlice({
  name: 'integration',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchGATrackingId.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchGATrackingId.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.gaTrackingId = action.payload.gaTrackingId;
      })
      .addCase(updateGATrackingId.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateGATrackingId.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.gaTrackingId = action.payload.gaTrackingId;
      });
  },
});

export default integrationSlice.reducer;

export const fetchGATrackingId = createAsyncThunk('integration/fetchGATrackingId', async (slug: string, thunkApi) => {
  const api = createAuthenticatedApiInstance(thunkApi.getState);
  try {
    const { data } = await api.get(`private/communities/${slug}/ga-tracking`);
    return data;
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

export const updateGATrackingId = createAsyncThunk('integration/updateGATrackingId', async ({ slug, gaTrackingId } : { slug: string; gaTrackingId: string }, thunkApi) => {
  try {
    const { data } = await api.post(`private/communities/${slug}/ga-tracking`, { gaTrackingId: gaTrackingId || null });
    return data;
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});
