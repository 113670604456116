const actionTypes = {
  FOLLOW_COMMUNITY: 'FOLLOW_COMMUNITY',
  FOLLOW_COMMUNITY_SUCCESS: 'FOLLOW_COMMUNITY_SUCCESS',
  FOLLOW_COMMUNITY_FAILURE: 'FOLLOW_COMMUNITY_FAILURE',

  UNFOLLOW_COMMUNITY: 'UNFOLLOW_COMMUNITY',
  UNFOLLOW_COMMUNITY_SUCCESS: 'UNFOLLOW_COMMUNITY_SUCCESS',
  UNFOLLOW_COMMUNITY_FAILURE: 'UNFOLLOW_COMMUNITY_FAILURE',

  FETCH_COMMUNITY_TOPICS: 'FETCH_COMMUNITY_TOPICS',
  FETCH_COMMUNITY_TOPICS_SUCCESS: 'FETCH_COMMUNITY_TOPICS_SUCCESS',
  FETCH_COMMUNITY_TOPICS_FAILURE: 'FETCH_COMMUNITY_TOPICS_FAILURE',
};

export default actionTypes;
