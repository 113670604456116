const isServer = typeof window === 'undefined';
const ENVIRONMENT = isServer ? process.env.ENVIRONMENT : window.ENVIRONMENT;

const environments = {
  'development-local': {
    SOLARIS_API_PATH: isServer ? 'https://solaris-api.hu-local.com/' : '/solaris-api/',
    SOLARIS_API_PUBLIC_PATH: isServer ? 'https://solaris-api.hu-local.com/' : '/solaris-api/',
    DISCOVER_API_PATH: 'https://discover-api.hu-local.com/',
    DISCOVER_API_KEY: 'API-KEY rXruMkVWQeXy7Bc9zgZjQN?WztRkDczA', // Staging API key
    CLASSIFIER_API_PATH: 'https://classifier-api.hu-local.com',
    FOOTFALL_API_PATH: '',
    IMG_PATH: 'https://images.hu-staging.be',
    CDN_PATH: 'https://assets.hu-staging.be',
    ASSET_PREFIX_PATH: '',
    environment: 'development-local',
    gaTrackingId: '',
    ga4TrackingId: '',
    COOKIE_CONTROL_API_KEY: '174d179d2f3c5170ff1a0bf8143fe6a265d29471',
    WS_URL: 'https://hu-local.com',
  },
  'development-staging': {
    SOLARIS_API_PATH: isServer ? 'https://solaris-api.hu-staging.be/' : '/solaris-api/',
    SOLARIS_API_PUBLIC_PATH: isServer ? 'https://hu-staging.be/public/' : '/solaris-api/',
    DISCOVER_API_PATH: 'https://discover-api.hu-staging.be/',
    DISCOVER_API_KEY: 'API-KEY nCCzNRytj@x2urV4&hfNyD,Fji9RwwpW',
    CLASSIFIER_API_PATH: isServer ? 'https://classifier-api.hu-staging.be/' : '/classifier-api/',
    FOOTFALL_API_PATH: 'https://hu-staging.be/footfall',
    IMG_PATH: 'https://images.hu-staging.be',
    CDN_PATH: 'https://assets.hu-staging.be',
    ASSET_PREFIX_PATH: '',
    environment: 'development',
    gaTrackingId: '', // UA-11066411-23 Betelgeuse Staging to debug locally
    ga4TrackingId: '', // G-8KMT47NK58 for G4 Betelgeuse Staging to debug locally
    COOKIE_CONTROL_API_KEY: '174d179d2f3c5170ff1a0bf8143fe6a265d29471',
    WS_URL: 'https://hu-staging.be',
  },
  'testing-staging': {
    SOLARIS_API_PATH: isServer ? 'https://solaris-api.hu-staging.be/' : '/solaris-api/',
    SOLARIS_API_PUBLIC_PATH: isServer ? 'https://hu-staging.be/public/' : '/solaris-api/',
    DISCOVER_API_PATH: 'https://discover-api.hu-staging.be/',
    DISCOVER_API_KEY: 'API-KEY nCCzNRytj@x2urV4&hfNyD,Fji9RwwpW',
    CLASSIFIER_API_PATH: isServer ? 'https://classifier-api.hu-staging.be/' : '/classifier-api/',
    FOOTFALL_API_PATH: 'https://hu-staging.be/footfall',
    IMG_PATH: 'https://images.hu-staging.be',
    CDN_PATH: 'https://assets.hu-staging.be',
    ASSET_PREFIX_PATH: '',
    environment: 'testing',
    gaTrackingId: '', // UA-11066411-23 Betelgeuse Staging to debug locally
    ga4TrackingId: '', // G-8KMT47NK58 for G4 Betelgeuse Staging to debug locally
    COOKIE_CONTROL_API_KEY: '174d179d2f3c5170ff1a0bf8143fe6a265d29471',
    WS_URL: 'https://hu-staging.be',
  },
  'development-production': {
    SOLARIS_API_PATH: isServer ? 'https://solaris.healthunlocked.com/' : '/solaris-api/',
    SOLARIS_API_PUBLIC_PATH: isServer ? 'https://healthunlocked.com/public/' : '/solaris-api/',
    DISCOVER_API_PATH: 'https://discover-api.hu-production.be/',
    DISCOVER_API_KEY: 'API-KEY [v4HVwTUCcccowGpcJqTddQsC3+/3BTs',
    CLASSIFIER_API_PATH: isServer ? 'https://classifier-api.hu-production.be/' : '/classifier-api/',
    FOOTFALL_API_PATH: 'https://healthunlocked.com/footfall',
    IMG_PATH: 'https://images.hu-production.be',
    CDN_PATH: 'https://assets.hu-production.be',
    ASSET_PREFIX_PATH: '',
    environment: 'development',
    gaTrackingId: '',
    ga4TrackingId: '',
    COOKIE_CONTROL_API_KEY: '21ad29290cdd60a97ce05df1864167a9764044e4',
  },
  'staging': {
    SOLARIS_API_PATH: isServer ? 'http://hu-nginx-internal.staging.hu-internal.be/' : 'https://hu-staging.be/',
    SOLARIS_API_PUBLIC_PATH: isServer ? 'http://solaris-api.staging.hu-internal.be/' : 'https://hu-staging.be/public/',
    DISCOVER_API_PATH: 'https://discover-api.hu-staging.be/',
    DISCOVER_API_KEY: 'API-KEY nCCzNRytj@x2urV4&hfNyD,Fji9RwwpW',
    CLASSIFIER_API_PATH: 'https://classifier-api.hu-staging.be/',
    FOOTFALL_API_PATH: 'https://hu-staging.be/footfall',
    IMG_PATH: 'https://images.hu-staging.be',
    CDN_PATH: 'https://assets.hu-staging.be',
    ASSET_PREFIX_PATH: 'https://assets.hu-staging.be/solaris',
    environment: 'staging',
    gaTrackingId: 'UA-11066411-23', // Betelgeuse Staging (UA)
    ga4TrackingId: 'G-8KMT47NK58', // G4 Betelgeuse Staging (GA4), _ga_8KMT47NK58 cookie in cookieControl.js
    COOKIE_CONTROL_API_KEY: '174d179d2f3c5170ff1a0bf8143fe6a265d29471',
  },
  'production': {
    SOLARIS_API_PATH: isServer ? 'http://hu-nginx-internal.production.hu-internal.be/' : 'https://healthunlocked.com/',
    SOLARIS_API_PUBLIC_PATH: isServer ? 'http://solaris-api.production.hu-internal.be/' : 'https://healthunlocked.com/public/',
    DISCOVER_API_PATH: 'https://discover-api.hu-production.be/',
    DISCOVER_API_KEY: 'API-KEY [v4HVwTUCcccowGpcJqTddQsC3+/3BTs',
    CLASSIFIER_API_PATH: 'https://classifier-api.hu-production.be/',
    FOOTFALL_API_PATH: 'https://healthunlocked.com/footfall',
    IMG_PATH: 'https://images.hu-production.be',
    CDN_PATH: 'https://assets.hu-production.be',
    ASSET_PREFIX_PATH: 'https://assets.hu-production.be/solaris',
    environment: 'production',
    gaTrackingId: 'UA-11066411-1', // Betelgeuse (UA)
    ga4TrackingId: 'G-PWYN7K2H5K', // G4 Betelgeuse (GA4), _ga_PWYN7K2H5K cookie in cookieControl.js
    COOKIE_CONTROL_API_KEY: '21ad29290cdd60a97ce05df1864167a9764044e4',
  },
};

module.exports = environments[ENVIRONMENT || 'production'];
