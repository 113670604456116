// @ts-strict-ignore
import axios from 'axios';
import config from 'config/config';

const {
  SOLARIS_API_PATH,
  SOLARIS_API_PUBLIC_PATH,
  DISCOVER_API_PATH,
  DISCOVER_API_KEY,
  CLASSIFIER_API_PATH,
  FOOTFALL_API_PATH,
} = config;
const isServer = typeof window === 'undefined';

axios.defaults.withCredentials = true;

export const createAuthenticatedApiInstance = (getState) => {
  // Note: the cookie is only required by the server side as the client side already has the cookie set
  if (!isServer) return createApiInstance();
  const sessionId = getState().context.sessionId;
  const authHeaders = {
    headers: {
      Cookie: sessionId ? `huSessID=${sessionId};` : '',
    },
  };

  return createApiInstance(authHeaders);
};

// 'createApiInstance' is used to customise the request headers on the server
// this is mainly used to set the cookie on the server side for authenticated requests
const createApiInstance = (authHeaders?: { headers: { Cookie: string } }) => {
  return {
    get: (url, config = {}) => {
      return axios.get(`${SOLARIS_API_PATH}${url}`, {
        ...authHeaders,
        ...config,
      });
    },

    getPublic: (url, config = {}) => {
      return axios.get(`${SOLARIS_API_PUBLIC_PATH}${url}`, {
        ...authHeaders,
        ...config,
      });
    },

    post: (url, args = {}, config = {}) => {
      return axios.post(`${SOLARIS_API_PATH}${url}`, args, {
        ...authHeaders,
        ...config,
      });
    },

    postPublic: (url, args = {}, config = {}) => {
      return axios.post(`${SOLARIS_API_PUBLIC_PATH}${url}`, args, {
        ...authHeaders,
        ...config,
      });
    },

    put: (url, args = {}, config = {}) => {
      return axios.put(`${SOLARIS_API_PATH}${url}`, args, {
        ...authHeaders,
        ...config,
      });
    },

    putPublic: (url, args = {}, config = {}) => {
      return axios.put(`${SOLARIS_API_PUBLIC_PATH}${url}`, args, {
        ...authHeaders,
        ...config,
      });
    },

    del: (url, args = {}, config = {}) => {
      return axios.delete(`${SOLARIS_API_PATH}${url}`, {
        ...args,
        ...authHeaders,
        ...config,
      });
    },

    discoverGet: (url, config = {}) => {
      return axios.get(`${DISCOVER_API_PATH}${url}`, {
        headers: {
          'Authorization': DISCOVER_API_KEY,
        },
        withCredentials: false,
        ...config,
      });
    },

    externalGet: (url) => {
      return axios.get(url, {
        withCredentials: false,
      });
    },

    classifierPost: (url, args = {}, config = {}) => {
      return axios.post(`${CLASSIFIER_API_PATH}${url}`, args, {
        headers: {
          'content-type': 'application/json',
        },
        withCredentials: false,
        ...config,
      });
    },

    footfallPost: (data) => {
      return axios.post(FOOTFALL_API_PATH, data, { headers: { 'Accept': 'application/json' } });
    },
  };
};

const api = createApiInstance();
export default api;
