// @ts-strict-ignore
import {
  CLEAR_SURVEY,
  NEXT_SCREEN,
  PREVIOUS_SCREEN,
  FETCH_PROFILE_SURVEY_CONDITION_SUGGESTIONS,
  FETCH_PROFILE_SURVEY_CONDITION_SUGGESTIONS_SUCCESS,
} from 'src/modules/profileSurvey/actionTypes';

export const initialState = {
  currentScreen: null,
  history: [],
  suggestions: {
    conditions: [],
    isLoading: true,
  },
};

export default function Reducer(state = initialState, action) {
  switch (action.type) {
    case CLEAR_SURVEY:
      return initialState;
    case NEXT_SCREEN: {
      let newHistory = [...state.history, state.currentScreen];

      // The history array shouldn't have either ConditionDetails or doubles.
      // As ConditionDetails screen returns to Conditions screen, this if statement takes care of both those cases:
      // If nextScreen is the last screen in history, remove it from history
      if (state.history[state.history.length - 1] === action.nextScreen) {
        newHistory = state.history.slice(0, state.history.length - 1);
      }

      return {
        ...state,
        currentScreen: action.nextScreen,
        history: newHistory,
      };
    }
    case PREVIOUS_SCREEN:
      return {
        ...state,
        currentScreen: state.history[state.history.length - 1],
        history: state.history.slice(0, state.history.length - 1),
      };
    case FETCH_PROFILE_SURVEY_CONDITION_SUGGESTIONS:
      return {
        ...state,
        suggestions: {
          ...state.suggestions,
          isLoading: true,
        },
      };
    case FETCH_PROFILE_SURVEY_CONDITION_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        suggestions: {
          ...state.suggestions,
          isLoading: false,
          conditions: action.suggestedConditions,
        },
      };
  }
  return state;
}
