import Link from 'next/link';
import router from 'next/router';
import { useDispatch, useSelector } from 'src/store/store';
import i18n from 'src/utils/translate';
import Modal from 'src/components/modals/modal';
import { P } from 'styles-js/mixins/typography';
import { muteUser } from 'src/modules/messages/actions';
import { LinkButton } from 'styles-js/buttons';
import { helpScoutArticle } from 'src/utils/helpScoutBeacon';
import { ffChatActionEnd } from 'src/modules/messages/footfall';

export default function MessageMuteModal(): JSX.Element {
  const dispatch = useDispatch();
  const { userId, muteUserId, muteUsername, section } = useSelector(({ modal }) => modal.modalProps);

  const learnMore = () => helpScoutArticle('25-how-do-i-mute-a-user-who-is-private-messaging-me');

  const onAccept = () => {
    ffChatActionEnd(section, 'mute');
    dispatch(muteUser(userId, muteUserId, muteUsername));
    if (router?.pathname !== '/messages') {
      router.replace('/messages');
    }
  };

  return (
    <Modal
      acceptButtonText={i18n.t('Mute')}
      cancelButtonText={i18n.t('Cancel')}
      title={i18n.t('Mute this user?')}
      onAccept={onAccept}
    >
      <P>{i18n.t('Muting will prevent you seeing any future messages from ')}{muteUsername}{'.'}</P>
      <P>
        {i18n.t('You may still see their messages in group chats.')}
        {' '}
        <LinkButton onClick={learnMore}>{i18n.t('Learn more')}{' >'}</LinkButton>
      </P>
    </Modal>
  );
}

export function MuteUserConfirmation({ username }: { username: string }): JSX.Element {
  return (
    <span>
      {i18n.t(`You have muted ${username}.`)}
      {' '}
      {i18n.t('You can unmute them by visiting')}
      {' '}
      <Link as={`/user/${username}`} href={`/user/profile?username=${username}`}>
        {i18n.t('their profile')}
      </Link>
    </span>
  );
}
