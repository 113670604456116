// @ts-strict-ignore
import actionTypes from 'src/modules/user/shared/actionTypes';
import contextActionTypes from 'src/modules/shared/context/actionTypes';
import { Condition } from 'src/constants/types';

export const initialState = {
  basics: {},
  conditions: [],
  interests: [],
  isLoading: true,
};

export default function Reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_PROFILE:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.FETCH_PROFILE_SUCCESS: {
      const { basics, conditions, interests } = action.data;
      return {
        ...state,
        basics,
        conditions,
        interests,
        isLoading: false,
      };
    }
    case actionTypes.UPDATE_PROFILE_BASICS_SUCCESS:
      return {
        ...state,
        basics: action.data,
      };
    case actionTypes.ADD_PROFILE_CONDITIONS_SUCCESS: {
      const existingCondition = state.conditions.find(x => x.id === action.condition.id);
      let conditionsArray: Condition[];
      if (existingCondition) {
        conditionsArray = [existingCondition, ...state.conditions.filter(x => x.id !== action.condition.id)];
      } else {
        conditionsArray = [action.condition, ...state.conditions];
      }
      return {
        ...state,
        conditions: conditionsArray,
      };
    }
    case actionTypes.UPDATE_PROFILE_CONDITION_SUCCESS:
      return {
        ...state,
        conditions: state.conditions.map((x => x.id === action.condition.id ? { ...action.condition, treatments: x.treatments } : x)),
      };
    case actionTypes.DELETE_PROFILE_CONDITION_SUCCESS:
      return {
        ...state,
        conditions: state.conditions.filter(x => x.id !== action.id),
      };
    case actionTypes.ADD_PROFILE_TREATMENT_SUCCESS:
      return {
        ...state,
        conditions: state.conditions.map((condition) => {
          if (condition.id === action.id) {
            const existingTreatment = condition.treatments.find(x => x.id === action.treatment.id);
            if (existingTreatment) {
              return { ...condition, treatments: [existingTreatment, ...condition.treatments.filter(x => x.id !== action.treatment.id)] };
            } else {
              return { ...condition, treatments: [action.treatment, ...condition.treatments] };
            }
          } else {
            return condition;
          }
        }),
      };
    case actionTypes.UPDATE_PROFILE_TREATMENT_SUCCESS:
      return {
        ...state,
        conditions: state.conditions.map((c) => {
          return (
            c.entityId === action.conditionId
              ? { ...c, treatments: c.treatments.map(t => t.id === action.id ? action.data : t) }
              : c
          );
        }),
      };
    case actionTypes.DELETE_PROFILE_TREATMENT_SUCCESS:
      return {
        ...state,
        conditions: state.conditions.map((x) => {
          return (
            x.id === action.conditionId
              ? { ...x, treatments: x.treatments.filter(t => t.id !== action.id) }
              : x
          );
        }),
      };
    case actionTypes.ADD_PROFILE_INTERESTS_SUCCESS:
      return {
        ...state,
        interests: action.interests,
      };
    case actionTypes.DELETE_PROFILE_INTEREST_SUCCESS:
      return {
        ...state,
        interests: state.interests.filter(x => x.id !== action.id),
      };
    case contextActionTypes.LOGOUT_SUCCESS:
      return initialState;
  }
  return state;
}
