// @ts-strict-ignore
import router from 'next/router';
import { captureException } from '@sentry/nextjs';
import actionTypes from 'src/modules/shared/context/actionTypes';
import { setAlert } from 'src/components/alert/actions';
import { closeModal } from 'src/modules/shared/modal/actions';
import api, { createAuthenticatedApiInstance } from 'src/utils/api';
import i18n from 'src/utils/translate';
import { getLocalStorageItem, Key } from 'src/utils/localStorage';
import { getDismissedFeaturedPostIds, formatFeaturedPosts } from 'src/utils/featuredPosts';
import { userIsCommunityMemberOrHUStaff } from 'src/utils/context';
import { emptyGuidelines } from 'src/constants/defaultCommunityGuidelines';
import { POST_URL } from 'src/constants/regex';
import { Lang } from 'src/constants/types';

export const fetchCurrentUser = () => {
  return async (dispatch, getState) => {
    dispatch({ type: actionTypes.FETCH_CURRENT_USER });
    try {
      const api = createAuthenticatedApiInstance(getState);
      const response = await api.get('private/user/current');
      return dispatch({
        type: actionTypes.FETCH_CURRENT_USER_SUCCESS,
        user: response.data,
      });
    } catch (ex) {
      captureException(ex);
      dispatch({ type: actionTypes.FETCH_CURRENT_USER_FAILURE });
    }
  };
};

export const fetchCurrentCommunity = (slug: string) => {
  return async (dispatch, getState) => {
    dispatch({ type: actionTypes.FETCH_CURRENT_COMMUNITY });
    try {
      const state = getState();
      const currentUser = state.context.currentUser;
      const isMember = userIsCommunityMemberOrHUStaff({ currentUser, communitySlug: slug });
      const api = createAuthenticatedApiInstance(getState);
      const encodedSlug = encodeURI(slug);
      const { data } = !isMember
        ? await api.getPublic(`communities/${encodedSlug}`)
        : await api.get(`private/communities/${encodedSlug}`);
      // fetch user permissions
      let permissionsData;
      if (currentUser) {
        const res = await api.get(`private/users/${currentUser.userId}/permissions`, { params: { communityId: data.communityId } });
        permissionsData = res.data || {};
      }
      dispatch({
        type: actionTypes.FETCH_CURRENT_COMMUNITY_SUCCESS,
        community: data,
        ...(currentUser && { isMember, ...permissionsData }),
      });
    } catch (ex) {
      const statusCode = ex?.response?.status ?? null;
      if (statusCode !== 314) { // temporary hack to avoid logging when redirecting to BG
        captureException(ex);
      }
      return dispatch({
        type: actionTypes.FETCH_CURRENT_COMMUNITY_FAILURE,
        statusCode,
      });
    }
  };
};

export const clearCurrentCommunity = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.CLEAR_CURRENT_COMMUNITY });
  };
};

export const fetchPermissions = (communityId: number) => {
  return async (dispatch, getState) => {
    dispatch({ type: actionTypes.FETCH_PERMISSIONS });
    try {
      const { context: { currentUser } } = getState();
      if (!currentUser) return;
      const api = createAuthenticatedApiInstance(getState);
      const { data: { permissions, roles } } = await api.get(`private/users/${currentUser.userId}/permissions`, { params: { communityId } });
      dispatch({
        type: actionTypes.FETCH_PERMISSIONS_SUCCESS,
        permissions,
        roles,
      });
    } catch (ex) {
      captureException(ex);
      dispatch({ type: actionTypes.FETCH_PERMISSIONS_FAILURE });
    }
  };
};

export const fetchUnreadMessagesCount = () => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.FETCH_UNREAD_MESSAGES_COUNT });
    try {
      const { data: { unreadMessages } } = await api.get('private/messages/unread-count');
      dispatch({ type: actionTypes.FETCH_UNREAD_MESSAGES_COUNT_SUCCESS, count: unreadMessages });
    } catch (ex) {
      captureException(ex);
      dispatch({ type: actionTypes.FETCH_UNREAD_MESSAGES_COUNT_FAILURE });
    }
  };
};

export const fetchUnreadNotificationsCount = () => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.FETCH_UNREAD_NOTIFICATIONS_COUNT });
    try {
      const { data: { unread } } = await api.get('private/notifications/unread');
      dispatch({ type: actionTypes.FETCH_UNREAD_NOTIFICATIONS_COUNT_SUCCESS, count: unread });
    } catch (ex) {
      captureException(ex);
      dispatch({ type: actionTypes.FETCH_UNREAD_NOTIFICATIONS_COUNT_FAILURE });
    }
  };
};

export const fetchNotifications = (size = 50) => {
  return async (dispatch, getState) => {
    dispatch({ type: actionTypes.FETCH_NOTIFICATIONS });
    try {
      const api = createAuthenticatedApiInstance(getState);
      const { data: { notifications = [] } } = await api.get('private/notifications/items', { params: { size, markread: true, from: 0 } });
      dispatch({ type: actionTypes.FETCH_NOTIFICATIONS_SUCCESS, notifications });
    } catch (ex) {
      captureException(ex);
      dispatch({ type: actionTypes.FETCH_NOTIFICATIONS_FAILURE });
    }
  };
};

export const updateLanguage = (lang: Lang) => {
  return async (dispatch, getState) => {
    dispatch({ type: actionTypes.UPDATE_LANGUAGE });
    try {
      const state = getState();
      const currentUser = state.context.currentUser;
      if (currentUser) {
        const { data: { status } } = await api.put(`private/users/${currentUser.userId}/account/language`,
          { lang });

        if (status && !status.success) {
          dispatch(setAlert(status.message, 'danger', 'none'));
          return dispatch({ type: actionTypes.UPDATE_LANGUAGE_FAILURE });
        }
      }
      dispatch({ type: actionTypes.UPDATE_LANGUAGE_SUCCESS, lang });
    } catch (ex) {
      captureException(ex);
      dispatch({ type: actionTypes.UPDATE_LANGUAGE_FAILURE });
      dispatch(setAlert());
    }
  };
};

export const resendVerificationLink = (signupParam = '') => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.RESEND_VERIFICATION_LINK });
    try {
      await api.post(`private/signup/resend-confirmation-link${signupParam}`);
      dispatch(setAlert(i18n.t('Verification email sent!'), 'success'));
      dispatch({ type: actionTypes.RESEND_VERIFICATION_LINK_SUCCESS });
    } catch (ex) {
      captureException(ex);
      dispatch({ type: actionTypes.RESEND_VERIFICATION_LINK_FAILURE });
    }
  };
};

export const resetFloatingSignupBox = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.RESET_FLOATING_SIGNUP_BOX });
  };
};

export const hideFloatingSignupBox = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.HIDE_FLOATING_SIGNUP_BOX });
  };
};

export const fetchGuidelines = (communitySlug, isPrivate = false) => async (dispatch) => {
  try {
    if (!communitySlug) {
      throw Error('fetchGuidelines: missing community-slug');
    }
    const response = isPrivate
      ? await api.get(`private/communities/${communitySlug}/guidelines`)
      : await api.getPublic(`communities/${communitySlug}/guidelines`);
    if (response.data) {
      dispatch({
        type: actionTypes.SET_COMMUNITY_GUIDELINES,
        slug: communitySlug,
        extendedGuidelinesUrl: response.data.extendedGuidelines,
        guidelines: response.data.guidelines,
      });
    } else {
      throw Error('fetchGuidelines: bad response');
    }
  } catch (ex) {
    captureException(ex);
  }
};

export const fetchGuidelinesOnce = (communitySlug, isPrivate) => async (dispatch, getState) => {
  const { context: { currentCommunity } } = getState();
  // don't fetch if user has dismissed guidelines
  const guidelinesDismissed = getLocalStorageItem(Key.guidelinesDismissed);
  if (guidelinesDismissed) {
    const communityIds = JSON.parse(guidelinesDismissed);
    if (Array.isArray(communityIds) && communityIds.includes(currentCommunity.communityId)) {
      return;
    }
  }
  // fetch if no guidelines or new community
  if (currentCommunity && (!currentCommunity.guidelines || currentCommunity.slug !== communitySlug)) {
    dispatch(fetchGuidelines(communitySlug, isPrivate));
  }
};

export const updateGuidelines = (communitySlug, guidelines = emptyGuidelines) => async (dispatch, getState) => {
  const api = createAuthenticatedApiInstance(getState);
  try {
    if (!communitySlug) {
      throw Error('updateGuidelines: missing community-slug');
    }
    const extendedGuidelinesTitleRemoved = guidelines.extendedGuidelinesUrl.replace(POST_URL, '$1');
    const response = await api.post(`private/communities/${communitySlug}/guidelines`, {
      extendedGuidelines: extendedGuidelinesTitleRemoved,
      guidelines: guidelines.guidelines,
    });
    if (response.data) {
      dispatch({
        type: actionTypes.SET_COMMUNITY_GUIDELINES,
        slug: communitySlug,
        extendedGuidelinesUrl: response.data.extendedGuidelines,
        guidelines: response.data.guidelines,
      });
    } else {
      throw Error('updateGuidelines: bad response');
    }
  } catch (ex) {
    captureException(ex);
    dispatch(setAlert(i18n.t('Error updating guidelines')));
  }
};

export const fetchCurrentCommunityRoles = (slug) => {
  return async (dispatch, getState) => {
    dispatch({ type: actionTypes.FETCH_CURRENT_COMMUNITY_ROLES });
    try {
      const api = createAuthenticatedApiInstance(getState);
      const response = await api.get(`private/communities/${slug}/badges`);
      if (response.status === 200 && response.data) {
        dispatch({
          type: actionTypes.FETCH_CURRENT_COMMUNITY_ROLES_SUCCESS,
          roles: response.data,
        });
      } else {
        dispatch(setAlert(i18n.t('Error fetching community badges, please try again.')));
      }
    } catch (ex) {
      dispatch({ type: actionTypes.FETCH_CURRENT_COMMUNITY_ROLES_FAILURE });
      dispatch(setAlert(i18n.t('Error fetching community badges, please try again.')));
      dispatch(closeModal());
      captureException(ex);
    }
  };
};

export const updateMemberRoles = (slug, userId, roles, username) => async (dispatch, getState) => {
  dispatch({ type: actionTypes.UPDATE_MEMBER_ROLES });
  try {
    const api = createAuthenticatedApiInstance(getState);
    const response = await api.post(`private/members/${slug}/badges`, { userId, roles });
    if (response.status === 200) {
      const roleString = response.data.roles.map(({ roleName }) => roleName).join(', ');
      const successMessage = roleString
        ? i18n.t(`${username} has been assigned the %1$s badge.`).replace('%1$s', roleString)
        : i18n.t(`${username} has had all badges removed.`);
      dispatch(setAlert(successMessage, 'success'));
      dispatch(closeModal());
      // UPDATE_MEMBER_ROLES_SUCCESS does not update the store
      // it may be useful if we need to do client side updates of member badges in the future
      // but client side updates will be complex
      // because badge editing can happen on 3 pages (members page, members search results page, SPP)
      dispatch({ type: actionTypes.UPDATE_MEMBER_ROLES_SUCCESS });
      setTimeout(() => router.reload(), 3000);
    } else {
      dispatch(setAlert(i18n.t(`Error updating member's badges, please try again.`)));
      dispatch({ type: actionTypes.UPDATE_MEMBER_ROLES_FAILURE });
    }
  } catch (ex) {
    captureException(ex);
    dispatch(setAlert(i18n.t(`Error updating member's badges, please try again.`)));
    dispatch({ type: actionTypes.UPDATE_MEMBER_ROLES_FAILURE });
  }
};

export const fetchFeaturedPosts = (params) => {
  return async (dispatch, getState) => {
    dispatch({ type: actionTypes.FETCH_FEATURED_POSTS });
    try {
      const api = createAuthenticatedApiInstance(getState);
      const { data: featuredPosts } = await api.getPublic('featured-posts', { params });
      dispatch({
        type: actionTypes.FETCH_FEATURED_POSTS_SUCCESS,
        featuredPosts: formatFeaturedPosts(featuredPosts.map((post) => ({ ...post, id: post.featuredPostId }))),
        dismissedFeaturedPostIds: getDismissedFeaturedPostIds(),
      });
    } catch (ex) {
      captureException(ex);
      dispatch({ type: actionTypes.FETCH_FEATURED_POSTS_FAILURE });
    }
  };
};

export const dismissFeaturedPost = () => ({
  type: actionTypes.DISMISS_FEATURED_POST_SUCCESS,
  dismissedFeaturedPostIds: getDismissedFeaturedPostIds(),
});

export const setIsAndroidUser = (isAndroidUser: boolean) => ({
  type: actionTypes.SET_IS_ANDROID_USER, isAndroidUser,
});
