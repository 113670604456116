import { EnrichedContent } from 'src/components/getElement';
import { Role } from 'src/constants/types/user';

export type Author = {
  age: number;
  authorId: number;
  avatar?: string; // post Author has avatar and avatarMedium
  bio: string;
  country: string;
  gender: string;
  healthInterests: { name: string }[];
  id: number;
  isHidden: boolean;
  avatarMedium: string;
  isDeleted: boolean;
  profileImageSmall2x?: string;
  roles: Role[];
  username: string;
  userImageHash: string;
}

export type EditAuthor = {
  id: number;
  username: string;
  avatar: string;
  avatarMedium: string;
  isDeleted: boolean;
  isHidden: boolean;
  roles: Role[];
}

export type MentionedUser = {
  userId: number;
  username: string;
}

export type Reply = {
  author: Author;
  body: string;
  dateCreated: string;
  editAuthor: EditAuthor | null;
  enriched?: Enriched;
  hasRated: boolean;
  id: number;
  imageDescription: string | null;
  imagePreview: string | null;
  imageUrl: string | null;
  indent: number;
  isLocked: boolean;
  mentionedUsers: MentionedUser[];
  numRatings: number | string;
  order: string;
  parentPostId: number;
  postImageHash: string | null;
  postTypeId: PostType;
  postUrl: string;
}

export type NestedReplies = Reply & {
  responses: Reply[];
};

export type Tag = {
  name: string;
  nodeId: string;
  urlEncodedName: string;
}

type Entity = {
  slug: string;
  name: string;
  shortName: string;
}

type Enriched = {
  body: EnrichedContent;
  snippet: EnrichedContent;
}

export type Post = {
  author: Author;
  body: string;
  categoryId: number | null;
  dateCreated: string;
  dateLastActivity: string;
  editAuthor: EditAuthor | null;
  enriched?: Enriched;
  entities: Entity[];
  hasRated?: boolean;
  id: number;
  imageDescription?: string | null;
  imageUrl: string | null;
  isDeleted?: boolean;
  isFollowing: boolean;
  isLocked: boolean;
  isPinned: boolean;
  isPrivate: boolean;
  mentionedUsers: MentionedUser[];
  numRatings: number | string;
  postImageHash: string | null;
  postTypeId: PostType;
  postUrl: string;
  repliesCount: number;
  responses: Reply[];
  tags: Tag[];
  title: string;
  totalResponses: number;
  url: string;
  urlEncodedTitle: string;
}

export type PinnedPost = {
  avatar?: string;
  postId: number;
  postTypeId?: number;
  title: string;
  userImageHash?: string;
  username: string;
  urlEncodedTitle?: string;
}

export type Poll = Post & {
  isSingleAnswer: boolean;
  choices: {
    id: string;
    title: string;
    votes: number;
  }[];
  isVotingAllowed: boolean;
  userVote: number[];
  isResults: boolean;
  highestVote: number;
}

// Post returned by endpoints pointing to Elastic Search (e.g. search & tag pages)
export type ElasticSearchPost = {
  author: {
    userId: number;
    username: string;
    userImageHash: string;
    roles: string[];
    dateDeleted: string | null ;
  };
  community: {
    communityId?: number;
    name: string;
    logoImageHash?: string;
    isPrivate?: boolean;
    slug: string;
  };
  dateCreated: string;
  highlight: string[];
  language: string;
  postId: number;
  title: string;
  totalResponses: number;
}

// Post in community list (e.g. community home & posts pages)
export type CommunityListPost = {
  author: {
    userId: number;
    username: string;
    userImageHash: string;
    roles: string[];
  };
  bodySnippet: string;
  communityId: number;
  dateCreated: string;
  isLocked: boolean;
  isPrivate: boolean;
  postId: number;
  title: string;
  totalResponses: number;
}

export type RelatedPost = {
  id: number;
  postId: number;
  postTypeId: PostType;
  title: string;
  snippet: string;
  communityId: number;
  isPrivate: boolean;
  dateCreated: string;
  totalResponses: number;
  author: {
    id: number;
    username: string;
    avatar: string;
    isDeleted: boolean;
    isHidden: boolean;
    roles: Role[];
  }
  community: {
    slug: string;
  }
  urlEncodedTitle: string;
}

//
// Next Post(s) in SPP sidebar
//
export type NextPost = {
  dateLastActivity: string;
  snippet: string;
  title: string;
  id: number;
  totalResponses: number;
  urlEncodedTitle: string;
  isPrivate: boolean;
  dateCreated: string;
  postTypeId: PostType;
  author: {
    id: number;
    username: string;
    avatar: string;
    isDeleted: boolean;
    isHidden: boolean;
    roles: Role[];
  }
  group: {
    id: number;
    name: string;
    avatar: string;
    groupCode: string;
    hasWebsite: boolean;
    hasSupportLink: boolean;
  }
}

export type Featured = {
  branch: undefined | 'A' | 'B';
  callToActionText: string;
  callToActionUrl: string;
  communityLogoImageMedium: string;
  featuredByCommunityName: string;
  featuredPostId: number;
  imageUrl?: string;
  logoImageHash: string;
  snippet: string;
  title: string;
}

export enum PostType {
  Question = 1,
  Answer = 2,
  Post = 4,
  Poll = 6,
  PollChoice = 7,
  Reply = 19,
}
