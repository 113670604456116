// @ts-strict-ignore
import actionTypes from 'src/modules/community/write/actionTypes';
import { Write } from 'src/constants/types/state';

export const initialState = {
  excludedTags: [],
  htmlBodyString: null,
  htmlTitleString: null,
  imageUploadError: null,
  isEdited: false,
  isLoading: false,
  isPoll: false,
  isPostPrivate: false,
  newImageHash: null,
  poll: null,
  post: null,
  postError: null,
  selectedTopic: null,
  tags: [],
};

export default function Reducer(state = initialState, action): Write {
  switch (action.type) {
    case actionTypes.EDIT_A_POST:
    case actionTypes.FETCH_POLL_EDIT:
    case actionTypes.WRITE_A_POST:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.EDIT_A_POST_SUCCESS:
    case actionTypes.EDIT_A_POST_FAILURE:
    case actionTypes.WRITE_A_POST_SUCCESS:
    case actionTypes.WRITE_A_POST_FAILURE:
      return {
        ...state,
        imageUploadError: null,
        isLoading: false,
        newImageHash: null,
      };
    case actionTypes.EXCLUDE_TAG: {
      const updatedTags = state.tags.filter(tag => tag.entityId !== action.entityId);
      return {
        ...state,
        excludedTags: state.excludedTags.concat(action.entityId),
        tags: updatedTags,
      };
    }
    case actionTypes.IMAGE_UPLOAD_SUCCESS:
      return {
        ...state,
        imageUploadError: null,
        newImageHash: action.hash,
      };
    case actionTypes.IMAGE_UPLOAD_FAILURE:
      return {
        ...state,
        imageUploadError: action.error,
      };
    case actionTypes.SET_IS_EDITED:
      return {
        ...state,
        isEdited: true,
      };
    case actionTypes.SET_TOPIC:
      return {
        ...state,
        selectedTopic: action.topic,
      };
    case actionTypes.SET_PRIVATE:
      return {
        ...state,
        isPostPrivate: action.isPostPrivate,
      };
    case actionTypes.SET_TAGS:
      return {
        ...state,
        tags: action.tags,
      };
    case actionTypes.SHOW_POLL_PAGE:
      return {
        ...initialState,
        isPoll: action.isPoll,
      };
    case actionTypes.FETCH_POLL_EDIT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        poll: action.poll,
      };
    case actionTypes.FETCH_POST_EDIT_SUCCESS:
      return {
        ...state,
        htmlTitleString: action.htmlTitleString,
        htmlBodyString: action.htmlBodyString,
        isPostPrivate: action.post.isPrivate,
        post: action.post,
      };
    case actionTypes.FETCH_POLL_EDIT_FAILURE:
    case actionTypes.FETCH_POST_EDIT_FAILURE:
      return {
        ...state,
        postError: action.error,
      };
    case actionTypes.RESET_WRITE_PAGE:
      return initialState;
  }
  return state;
}
