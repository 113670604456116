import i18n from 'src/utils/translate';
import { theme } from 'styles-js/theme';

export const ethnicityList = [
  { value: 'white', label: i18n.t('White / Caucasian'), colour: theme.colorGreen },
  { value: 'black', label: i18n.t('Black / African Diaspora'), colour: theme.colorPurple },
  { value: 'latino', label: i18n.t('Latino / Hispanic'), colour: theme.colorYellow },
  { value: 'asian', label: i18n.t('Asian'), colour: theme.colorOrange },
  { value: 'indian', label: i18n.t('American Indian / Alaska Native'), colour: theme.colorPinkLight },
  { value: 'mid-eastern', label: i18n.t('Middle Eastern / North African'), colour: theme.colorBlueLight },
  { value: 'south-asian', label: i18n.t('South Asian'), colour: theme.colorSuccessBackground },
  { value: 'east-asian', label: i18n.t('East Asian'), colour: theme.colorWarning },
  { value: 'hawaiians', label: i18n.t('Pacific Islanders'), colour: theme.colorBlue },
  { value: 'mixed', label: i18n.t('Mixed / Multiple ethnic groups'), colour: theme.colorDanger },
  { value: 'other', label: i18n.t('Other ethnic group'), colour: theme.colorPurpleLight  },
  { value: 'none', label: i18n.t('Prefer not to say'), colour: theme.colorBlueDark },
];
