// @ts-strict-ignore
import styled from 'styled-components';
import { Colours } from 'styles-js/theme';

// styles/components/shared/icons.scss
export type IconType =
'alert' |
'at' |
'bold' |
'close' |
'cog' |
'cross' |
'eye' |
'eye-off' |
'facebook' |
'help' |
'in-reply' |
'information' |
'instagram' |
'italic' |
'like-filled' |
'like-outline' |
'link-ext' |
'lock' |
'logout' |
'mail' |
'newsfeed' |
'ok' |
'open-down' |
'open-left' |
'open-right' |
'open-up' |
'pencil' |
'people' |
'picture' |
'plus' |
'profile' |
'search' |
'settings' |
'smile' |
'twitter' |
'underline' |
'youtube' |
'zoom-in';

export default function Icon({ icon, className = '', title, colour }: { icon: IconType, className?: string, title?: string, colour?: keyof Colours }): JSX.Element {
  return (
    <Span $colour={colour} className={`icon icon-${icon} ${className}`} title={title} />
  );
}

const Span = styled.span<{ $colour?: keyof Colours }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${({ $colour, theme }) => theme[$colour]};
`;
