// @ts-strict-ignore
import { useDispatch } from 'src/store/store';
import i18n from 'src/utils/translate';
import { setLastMonthPage, setPreviousMonthPage } from 'src/modules/community/communityMetrics/moderation/moderationSlice';
import { NextPage } from 'src/components/pagination/nextPageLink';
import { pageRange } from 'src/components/pagination/pageLinks';
import { Ul } from 'src/components/pagination/pagination';
import Icon from 'src/components/icon';
import { LinkButton } from 'styles-js/buttons';
import paginationResultsPerPage from 'src/constants/paginationResultsPerPage';

export enum TableType {
  Posters = 'Posters',
  Repliers = 'Repliers',
  Reported = 'Reported',
}

export enum MonthType {
  Last = 'Last',
  Previous = 'Previous',
}

type Props = {
  lastPageOverride?: number,
  monthType: MonthType,
  page: number,
  total: number,
  tableType: TableType,
};

export default function Pagination({
  lastPageOverride = 500,
  monthType,
  page,
  total = 0,
  tableType,
}: Props) {
  if (total === 0) return null;

  return (
    <>
      <Ul>
        <PageLinks
          lastPageOverride={lastPageOverride}
          monthType={monthType}
          page={page}
          tableType={tableType}
          total={total}
        />
        <NextPageLink
          lastPageOverride={lastPageOverride}
          monthType={monthType}
          page={page}
          tableType={tableType}
          total={total}
        />
      </Ul>
    </>
  );
}

function PageLinks({ lastPageOverride, monthType, page, total, tableType }) {
  const dispatch = useDispatch();
  const lastPage = Math.ceil(total / paginationResultsPerPage.communityMetricsTables);
  const maxPageNum = lastPage < lastPageOverride ? lastPage : lastPageOverride; // temporary fix for error on pages 500+
  const range = pageRange(page, maxPageNum);

  const changePage = (page: number) => {
    if (monthType === MonthType.Previous) {
      dispatch(setPreviousMonthPage({ page, tableType }));
    } else {
      dispatch(setLastMonthPage({ page, tableType }));
    }
  };

  return range.map((p) => {
    if (p === page) {
      return <li className="active" key={p}>{p}</li>;
    } else if (typeof(p) === 'number') {
      return <li key={p}><LinkButton onClick={() => changePage(p)}>{p}</LinkButton></li>;
    } else {
      return <li className="dots" key={p}>{p}</li>;
    }
  });
}

function NextPageLink({ lastPageOverride, monthType, page, total, tableType }) {
  const dispatch = useDispatch();
  const lastPage = Math.ceil(total / paginationResultsPerPage.communityMetricsTables);
  const isLastPage = page === lastPage || page === lastPageOverride;

  const changePage = (page: number) => {
    if (monthType === MonthType.Previous) {
      dispatch(setPreviousMonthPage({ page, tableType }));
    } else {
      dispatch(setLastMonthPage({ page, tableType }));
    }
  };

  if (!isLastPage) {
    return (
      <NextPage>
        <LinkButton onClick={() => changePage(page + 1)}>
          {i18n.t('Next page')}
          <Icon icon="open-right" />
        </LinkButton>
      </NextPage>
    );
  }
}
