// @ts-strict-ignore

export const initialState = {
  alert: null,
};

export default function Reducer(state = initialState, action) {
  switch (action.type) {
    case 'ALERT_DISMISS':
      return {
        ...state,
        alert: null,
      };
    case 'ALERT_SET':
      return {
        ...state,
        alert: action.alert,
      };
    default:
      return state;
  }
}
