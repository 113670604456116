import styled, { css } from 'styled-components';

// BODY

export const text = css`
  font-family: ${props => props.theme.fontFamilyMain};
  font-size: ${props => props.theme.fontSizeS};
  line-height: ${props => props.theme.fontLineHeightS};
  font-weight: ${props => props.theme.fontWeightNormal};
  color: ${props => props.theme.colorTextBlack};
  text-decoration: none;
`;

export const textWideSpacing = css`
  ${text};
  line-height: ${props => props.theme.fontLineHeightSWide};
`;

export const textLight = css`
  ${text};
  color: ${props => props.theme.colorTextLight};
`;

export const textSmall = css`
  font-family: ${props => props.theme.fontFamilyMain};
  font-size: ${props => props.theme.fontSizeXs};
  line-height: ${props => props.theme.fontLineHeightXs};
  font-weight: ${props => props.theme.fontWeightNormal};
  color: ${props => props.theme.colorTextBlack};
  text-decoration: none;
`;

export const textSmallLight = css`
  ${textSmall};
  color: ${props => props.theme.colorTextLight};
`;

export const P = styled.p`
  margin-bottom: 10px;
`;

// TITLES

export const titleXsmall = css`
  font-family: ${props => props.theme.fontFamilyTitle};
  font-size: 13px;
  line-height: 16px;
  font-style: normal;
  font-weight: ${props => props.theme.fontWeightBold};
  color: ${props => props.theme.colorTextBlack};
  text-decoration: none;
`;

export const titleSmall = css`
  font-family: ${props => props.theme.fontFamilyTitle};
  font-size: ${props => props.theme.fontSizeS};
  line-height: ${props => props.theme.fontLineHeightS};
  font-style: normal;
  font-weight: ${props => props.theme.fontWeightNormalTitle};
  color: ${props => props.theme.colorTextBlack};
  text-decoration: none;
`;

export const titleMedium = css`
  font-family: ${props => props.theme.fontFamilyTitle};
  font-size: ${props => props.theme.fontSizeM};
  line-height: ${props => props.theme.fontLineHeightM};
  font-style: normal;
  font-weight: ${props => props.theme.fontWeightNormalTitle};
  color: ${props => props.theme.colorTextBlack};
  text-decoration: none;
`;

export const titleLarge = css`
  font-family: ${props => props.theme.fontFamilyTitle};
  font-size: ${props => props.theme.fontSizeL};
  line-height: ${props => props.theme.fontLineHeightL};
  font-style: normal;
  font-weight: ${props => props.theme.fontWeightNormalTitle};
  color: ${props => props.theme.colorTextBlack};
  text-decoration: none;

  @media (${props => props.theme.underScreenSmall}) {
    font-size: ${props => props.theme.fontSizeM};
    line-height: ${props => props.theme.fontLineHeightM};
  }
`;

export const titleXlarge = css`
  font-family: ${props => props.theme.fontFamilyTitle};
  font-size: ${props => props.theme.fontSizeXl};
  line-height: ${props => props.theme.fontLineHeightXl};
  font-style: normal;
  font-weight: ${props => props.theme.fontWeightNormalTitle};
  color: ${props => props.theme.colorTextBlack};
  text-decoration: none;

  @media (${props => props.theme.underScreenSmall}) {
    font-size: ${props => props.theme.fontSizeM};
    line-height: ${props => props.theme.fontLineHeightM};
  }
`;


// LINKS

export const textLinkPrimarySmall = css`
  ${textSmall};
  color: ${props => props.theme.colorTextLinkPrimary};
  transition: all 0.5s ease;

  &:hover {
    color: ${props => props.theme.colorTextLinkPrimaryHover};
    cursor: pointer;
  }
`;

export const textLinkPrimary = css`
  ${text};
  color: ${props => props.theme.colorTextLinkPrimary};
  transition: all 0.5s ease;

  &:hover {
    color: ${props => props.theme.colorTextLinkPrimaryHover};
    cursor: pointer;
  }
`;

export const textLinkSecondary = css`
  ${text};
  color: ${props => props.theme.colorTextLinkSecondary};
  transition: all 0.5s ease;

  &:hover {
    color: ${props => props.theme.colorGreyDarkHover};
    cursor: pointer;
  }
`;

export const textLinkWhite = css`
  ${text};
  color: ${props => props.theme.colorTextWhite};
  transition: all 0.5s ease;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;
