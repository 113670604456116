const actionTypes = {
  FETCH_PROFILE: 'FETCH_PROFILE',
  FETCH_PROFILE_SUCCESS: 'FETCH_PROFILE_SUCCESS',
  FETCH_PROFILE_FAILURE: 'FETCH_PROFILE_FAILURE',

  UPDATE_PROFILE_BASICS: 'UPDATE_PROFILE_BASICS',
  UPDATE_PROFILE_BASICS_SUCCESS: 'UPDATE_PROFILE_BASICS_SUCCESS',
  UPDATE_PROFILE_BASICS_FAILURE: 'UPDATE_PROFILE_BASICS_FAILURE',

  ADD_PROFILE_CONDITIONS_SUCCESS: 'ADD_PROFILE_CONDITIONS_SUCCESS',
  ADD_PROFILE_CONDITIONS_FAILURE: 'ADD_PROFILE_CONDITIONS_FAILURE',

  UPDATE_PROFILE_CONDITION_SUCCESS: 'UPDATE_PROFILE_CONDITION_SUCCESS',
  UPDATE_PROFILE_CONDITION_FAILURE: 'UPDATE_PROFILE_CONDITION_FAILURE',

  DELETE_PROFILE_CONDITION_SUCCESS: 'DELETE_PROFILE_CONDITION_SUCCESS',
  DELETE_PROFILE_CONDITION_FAILURE: 'DELETE_PROFILE_CONDITION_FAILURE',

  ADD_PROFILE_TREATMENT_SUCCESS: 'ADD_PROFILE_TREATMENT_SUCCESS',
  ADD_PROFILE_TREATMENT_FAILURE: 'ADD_PROFILE_TREATMENT_FAILURE',

  UPDATE_PROFILE_TREATMENT_SUCCESS: 'UPDATE_PROFILE_TREATMENT_SUCCESS',
  UPDATE_PROFILE_TREATMENT_FAILURE: 'UPDATE_PROFILE_TREATMENT_FAILURE',

  DELETE_PROFILE_TREATMENT_SUCCESS: 'DELETE_PROFILE_TREATMENT_SUCCESS',
  DELETE_PROFILE_TREATMENT_FAILURE: 'DELETE_PROFILE_TREATMENT_FAILURE',

  ADD_PROFILE_INTERESTS_SUCCESS: 'ADD_PROFILE_INTERESTS_SUCCESS',
  ADD_PROFILE_INTERESTS_FAILURE: 'ADD_PROFILE_INTERESTS_FAILURE',

  DELETE_PROFILE_INTEREST_SUCCESS: 'DELETE_PROFILE_INTEREST_SUCCESS',
  DELETE_PROFILE_INTEREST_FAILURE: 'DELETE_PROFILE_INTEREST_FAILURE',
};

export default actionTypes;
