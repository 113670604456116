import styled from 'styled-components';
import Link from 'next/link';
import i18n from 'src/utils/translate';
import Icon from 'src/components/icon';
import { useUpdateSearchParam } from 'src/modules/search/hooks';

type Props = {
  page: number,
  pageSize: number,
  total: number,
  lastPageOverride?: number,
};
export default function NextPageLink({ page, pageSize, total, lastPageOverride }: Props) {
  const getLinkProps = useUpdateSearchParam('page');

  const lastPage = Math.ceil(total / pageSize);
  const isLastPage = page === lastPage || page === lastPageOverride;

  if (!isLastPage) {
    const { as, href } = getLinkProps(String(page + 1));
    return (
      <NextPage>
        <Link as={as} href={href} scroll={false}>
          {i18n.t('Next page')}
          <Icon icon="open-right" />
        </Link>
      </NextPage>
    );
  }
}

export const NextPage = styled.li`
  .icon {
    margin-left: 5px;
  }
  @media (${({ theme }) => theme.underScreenSmall}) {
    display: none;
  }
`;
