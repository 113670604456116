import i18n from 'src/utils/translate';

export default function DoughnutChartFallback({ labels, total, values }: { labels: string[], total: string, values: number[] }) {
  return (
    <>
      <ul>{labels.map((label, index) => <li key={label}>{`${label}: ${values[index]}%`}</li>)}</ul>
      <p>{i18n.t('TOTAL')}: {total}</p>
    </>
  );
}
