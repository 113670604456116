// @ts-strict-ignore
import { clean, prune, stripTags } from 'underscore.string';
import { getCommunityLogoUrl } from 'src/utils/linkUtils';
import config from 'config/config';
import { Community, Metadata, Poll, Post } from 'src/constants/types';

const shouldIndexPost = (post: Post): boolean => !post.isPrivate && post.totalResponses > 0 && (post.body || '').length > 280;

export const getPostMetaTitle = (post: Post, community: Community): string => {
  // our seo friends have advised us that we need titles to be as specific as possible while not exceeding 65 characters.
  // so, we drop the HealthUnlocked from the title (not very specific) and do the following:
  // 1. the meta title ends with the community name, up to 20 characters (after which it is cut off)
  // 2. the meta title starts with the title of the post, as much of it as possible up to a max of 65 characters along with the community name
  // 3. if there are more than 10 characters left available, we include as many characters as we can from the body of the post up to the max of 65.
  const charsForCommunity = 20;
  const communityName = community.name || '';
  const communityNameSnippet = communityName.length > charsForCommunity
    ? communityName.substring(0, charsForCommunity - 3) + '...'
    : communityName;
  const charsForPostTitle = 60 - communityNameSnippet.length;
  const postTitle = post.title || '';
  const postTitleSnippet = postTitle.length > charsForPostTitle
    ? postTitle.substring(0, charsForPostTitle - 3) + '...'
    : postTitle;
  const charsForSnippet = charsForPostTitle - postTitleSnippet.length - 3;
  const bodySnippet = charsForSnippet > 10
    ? ': ' + prune(getSeoDescription(post?.enriched?.body || []), charsForSnippet)
    : '';
  return `${postTitleSnippet}${bodySnippet} - ${communityNameSnippet}`;
};

export const getPostSeo = (post: Post, community: Community): Metadata => ({
  canonical: `https://healthunlocked.com/${community?.slug}/posts/${post.id}/${post.urlEncodedTitle}`,
  published_time: post.dateCreated,
  modified_time: post.dateLastActivity,
  title: getPostMetaTitle(post, community),
  description: prune(getSeoDescription(post?.enriched?.body || []), 200),
  image: community?.logoImageHash ? getCommunityLogoUrl(community?.logoImageHash) : null,
  robots: shouldIndexPost(post) ? '' : 'noindex, nofollow',
});

export const getPollSeo = (poll: Poll, community: Community): Metadata => {
  if (!poll || !community) return {};
  const choices = poll.choices && poll.choices.length > 0 && poll.choices.map(c => c.title.trim()).join(', ');
  const description = choices ? `${poll.title} ${choices}` : poll.title;
  return {
    canonical: `https://healthunlocked.com/${community?.slug}/polls/${poll.id}/${poll.urlEncodedTitle}/result`,
    published_time: poll.dateCreated,
    modified_time: poll.dateLastActivity,
    title: getPostMetaTitle(poll, community),
    description: prune(description, 200),
    image: community?.logoImageHash ? getCommunityLogoUrl(community?.logoImageHash) : null,
    robots: shouldIndexPost(poll) ? '' : 'noindex, nofollow',
  };
};

export const getCommunitySeo = (community: Community): Metadata => {
  if (!community) return {};
  return {
    canonical: `https://healthunlocked.com/${community?.slug}`,
    title: `${community?.name} | HealthUnlocked`,
    description: community?.description,
    image: getCommunityLogoUrl(community?.logoImageHash),
  };
};

export const getCommunityPostsSeo = (
  community: Community,
  communityTopTags = [],
  archiveCurrentYear,
  archiveCurrentMonth,
  currentPage,
  isPollsList,
): Metadata => {
  if (!community) return {};
  let description = 'Read community conversations. Get support and information from people sharing their experiences';
  if (communityTopTags.length) {
    description += ' with' + communityTopTags.map(tag => tag.name.toLowerCase()).map(tagName => ` ${tagName}`) + ' and more.';
  }
  if (archiveCurrentYear && archiveCurrentMonth) {
    return {
      canonical: `https://healthunlocked.com/${community?.slug}/posts/archive/${archiveCurrentYear}/${archiveCurrentMonth}?page=${currentPage}`,
      title: `Posts - ${community?.name} | HealthUnlocked`,
      description: description,
      image: getCommunityLogoUrl(community?.logoImageHash),
    };
  } else if (isPollsList) {
    return {
      canonical: `https://healthunlocked.com/${community?.slug}/polls`,
      title: `Polls - ${community?.name} | HealthUnlocked`,
      description: description,
      image: getCommunityLogoUrl(community?.logoImageHash),
    };
  } else {
    return {
      canonical: `https://healthunlocked.com/${community?.slug}/posts`,
      title: `Posts - ${community?.name} | HealthUnlocked`,
      description: description,
      image: getCommunityLogoUrl(community?.logoImageHash),
    };
  }
};

export const getCommunitySearchSeo = (community): Metadata => {
  if (!community) return {};
  return {
    canonical: `https://healthunlocked.com/${community?.slug}/search/posts`,
    title: `Search - ${community?.name} | HealthUnlocked`,
    description: community?.description,
    image: getCommunityLogoUrl(community?.logoImageHash),
    robots: 'noindex',
  };
};

export const getCommunityMembersSeo = (community): Metadata => {
  if (!community) return {};
  return {
    canonical: `https://healthunlocked.com/${community?.slug}/members`,
    title: `Members - ${community?.name} | HealthUnlocked`,
    description: '',
    image: getCommunityLogoUrl(community?.logoImageHash),
    robots: 'noindex',
  };
};

export const getCommunityAboutSeo = (community: Community): Metadata => {
  if (!community) return {};
  return {
    canonical: `https://healthunlocked.com/${community?.slug}/about`,
    title: `About - ${community?.name} | HealthUnlocked`,
    description: community?.description,
    image: getCommunityLogoUrl(community?.logoImageHash),
    robots: community.isPrivate ? 'noindex' : '',
  };
};

export const getProgramsAboutSeo = (programSlug, programName = '', description = ''): Metadata => {
  if (!programSlug) return {};
  return {
    canonical: `https://healthunlocked.com/programs/${programSlug}`,
    title: `${programName} | HealthUnlocked`,
    description: description,
    image: `${config.cdn.programs}${programSlug}/landing-page-banner.jpg`,
  };
};

export const getProgramDescription = (description) => {
  const trimWhitespace = (str) => str.trim(); // trim: trim whitespace characters from begining and ending of the string
  const stripHtmlTags = (str) => stripTags(str); // stripTags: removing any HTML tags (for iframes for example)
  const removeNewLines = (str) => str.replace(/[\n\r]/g, ' '); // replace new lines with a whitespace character
  const removeImages = (str) => str.replace(/!\[[^[]+\]\([^(]+\)/gi, ' '); // remove images ![alt](some_url)
  const removeLinks = (str) => str.replace(/\[([^[]+)\]\([^(]+\)/gi, '$1'); // replace links [title](some_url) with the title
  const cleanWhitespace = (str) => clean(str); // clean: compress some whitespaces to one (the ones we've added with replace above)
  const pruneLength = (str) => prune(str, 200); // prune: truncate string to 200 characters
  return pruneLength(cleanWhitespace(removeLinks(removeImages(removeNewLines(stripHtmlTags(trimWhitespace(description)))))));
};

export const getProgramsModuleSeo = (programSlug, programName = '', moduleCode, currentModule): Metadata => {
  if (!programSlug) return {};
  const moduleName = currentModule ? currentModule.name : '';
  const moduleSections = currentModule ? currentModule.sections : undefined;
  // For the description meta tag, we take the first 200 characters of the first section of the module
  // As there can be images, links or html tags, we remove those to only have text
  let description = '';
  if (moduleSections) {
    description = getProgramDescription(moduleSections[0].content);
  }

  return {
    canonical: `https://healthunlocked.com/programs/${programSlug}/${moduleCode}`,
    title: `${moduleName} | ${programName} | HealthUnlocked`,
    description,
  };
};

export const getTagsListSeo = (letter: string): Metadata => ({
  canonical: `https://healthunlocked.com/tags/${letter.toLowerCase()}`,
  title: 'Health terms A to Z | HealthUnlocked',
  description: 'Find all health terms (conditions, treatments, wellbeing interests etc) discussed on HealthUnlocked',
});

export const getUnsubscribeSeo = (): Metadata => ({
  description: '',
  title: 'Unsubscribe | HealthUnlocked',
  robots: 'noindex',
});

export const getProfileSeo = (username, profileImageMedium): Metadata => {
  if (!username) return {};
  return {
    title: `${username}'s profile on HealthUnlocked`,
    description: `${username}'s profile on HealthUnlocked`,
    image: profileImageMedium,
    robots: 'noindex, nofollow',
  };
};

export const isUserBot = typeof window !== 'undefined' && /bot|googlebot|crawler|spider|robot|crawling/i.test(navigator.userAgent);

const getSeoDescription = (enrichedText): string => enrichedText.reduce((description, item) => {
  switch (item.type) {
    case 'mention': return description + item.username;
    case 'text': return description + item.text;
    case 'link': return description + item.text;
    case 'return': return description + ' ';
    case 'smiley': return description + item.originalText;
    default: return description + (item.text || '');
  }
}, '');
