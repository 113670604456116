import styled, { css } from 'styled-components';
import { text, textLinkPrimary } from 'styles-js/mixins/typography';

export const ButtonBase = css`
  ${text};
  display: inline-block;
  margin: 5px 5px 5px 0px;
  padding: 6px 12px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.7s ease;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  &:hover {
    color: ${props => props.theme.colorTextBlack};
    text-decoration: none;
  }
`;

export const PrimaryButtonStyles = css`
  color: ${({ theme }) => theme.colorButtonPrimaryText};
  background-color: ${({ theme }) => theme.colorButtonPrimary};
  border-color: ${({ theme }) => theme.colorButtonPrimary};
  &:hover {
    color: ${({ theme }) => theme.colorButtonPrimaryText};
    background-color: ${({ theme }) => theme.colorButtonPrimaryHover};
    border-color: ${({ theme }) => theme.colorButtonPrimaryHover};
  }
  &:disabled {
    color: ${({ theme }) => theme.colorBlack};
    background-color: ${({ theme }) => theme.colorGreyLight};
    border-color: ${({ theme }) => theme.colorGreyLight};
  }
`;

export const SecondaryButtonStyles = css`
  color: ${({ theme }) => theme.colorButtonSecondaryText};
  background-color: ${({ theme }) => theme.colorWhite};
  border-color: ${({ theme }) => theme.colorButtonSecondaryBorder};
  &:hover {
    color: ${({ theme }) => theme.colorButtonSecondaryBorderHover};
    border-color: ${({ theme }) => theme.colorButtonSecondaryBorderHover};
    background-color: ${({ theme }) => theme.colorWhite};
  }
  &:disabled {
    color: ${({ theme }) => theme.colorBlack};
    border-color: ${({ theme }) => theme.colorGreyLight};
  }
`;

export const TertiaryButtonStyles = css`
  background-color: ${({ theme }) => theme.colorWhite};
  border-color: ${({ theme }) => theme.colorButtonTertiaryBorder};
  &:hover, &:focus {
    border-color: ${({ theme }) => theme.colorButtonTertiaryBorderHover};
  }
  &:disabled {
    color: ${({ theme }) => theme.colorBlack};
    background-color: ${({ theme }) => theme.colorButtonTertiaryBorder};
  }
`;

export const LinkButtonStyles = css`
  ${textLinkPrimary};
  padding: 0;
  &:disabled {
    color: ${({ theme }) => theme.colorGreyDark};
    pointer-events: none;
  }
`;

export const NoneButtonStyles = css`
  margin: 0;
`;

export const DangerButtonStyles = css`
  color: ${({ theme }) => theme.colorWhite};
  background-color: ${({ theme }) => theme.colorDanger};
  border-color: ${({ theme }) => theme.colorDanger};
`;

export const PrimaryButton = styled.button<{ $fullWidth?: boolean }>`
  ${ButtonBase};
  ${PrimaryButtonStyles};
  ${props => props.$fullWidth && css`
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  `};
`;

export const SecondaryButton = styled.button<{ $fullWidth?: boolean }>`
  ${ButtonBase};
  ${SecondaryButtonStyles};
  ${props => props.$fullWidth && css`
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  `};
`;

export const TertiaryButton = styled.button<{ $fullWidth?: boolean }>`
  ${ButtonBase};
  ${TertiaryButtonStyles};
  ${props => props.$fullWidth && css`
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  `};
`;

export const LinkButton = styled.button<{ $fullWidth?: boolean }>`
  ${ButtonBase};
  ${LinkButtonStyles};
  /* We might be able to move the following rules to LinkButtonStyles but needs checking */
  border: 0;
  margin: 0;
  appearance: none;
  vertical-align: baseline;
  ${props => props.$fullWidth && css`
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  `};
`;

export const NoneButton = styled.button<{ $fullWidth?: boolean }>`
  ${ButtonBase};
  ${NoneButtonStyles};
  ${props => props.$fullWidth && css`
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  `};
`;

export const DangerButton = styled.button<{ $fullWidth?: boolean }>`
  ${ButtonBase};
  ${DangerButtonStyles};
  ${props => props.$fullWidth && css`
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  `};
`;

export const SearchBarIconButton = styled.button.attrs<{ 'data-testid'?: string }>({
  'data-testid': 'search-bar-icon-button',
})`
  ${ButtonBase};
  width: 60px;
  font-size: 25px;
  border-radius: 0 5px 5px 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
  &:disabled {
    background-color: ${({ theme }) => theme.colorBlueBackground};
    border-color: ${({ theme }) => theme.colorBlueBackground};
    opacity: 1;
  }
  &:disabled:hover {
    background-color: ${({ theme }) => theme.colorGreyLight};
  }
  .search-bar__container--focused & {
    background-color: ${({ theme }) => theme.colorBlue};
    border-color: ${({ theme }) => theme.colorBlue};
    color: ${({ theme }) => theme.colorWhite};
  }
`;
