// @ts-strict-ignore
import { useState } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'src/store/store';
import { fetchCurrentUser } from 'src/modules/shared/context/actions';
import { setModalProps } from 'src/modules/shared/modal/actions';
import { trackFootfallEvent } from 'src/utils/footfall';
import { Event } from 'src/constants/footfall';
import { Header, MultiScreenModal } from 'src/components/modals/modal';
import Screen from 'src/modules/shared/modal/components/signup/onboardingScreens/onboardingScreen';

export enum SignupFlow {
  Community = 'Community',
  Invitation = 'Invitation',
}
export enum Screens {
  Username = 'Username',
  AcceptTerms = 'AcceptTerms',
  AcceptResearch = 'AcceptResearch',
  CheckEmail = 'CheckEmail',
  FindCommunities = 'FindCommunities',
  Welcome = 'Welcome',
}

export default function Onboarding() {
  const { startScreen = Screens.Username } = useSelector(({ modal }) => modal.modalProps);
  const [currentScreen, setCurrentScreen] = useState(startScreen);
  const [username, setUsername] = useState(null);
  const percentComplete = usePercentComplete(currentScreen);

  return (
    <MultiScreenModal responsive={false}>
      <Header
        hasCloseButton={false}
        progress={percentComplete}
      />
      <Screen
        currentScreen={currentScreen}
        setCurrentScreen={setCurrentScreen}
        setUsername={setUsername}
        username={username}
      />
    </MultiScreenModal>
  );
}

export function useIsSkipFindCommunity() {
  const { signupFlow } = useSelector(({ modal }) => modal.modalProps);
  return signupFlow === SignupFlow.Community || signupFlow === SignupFlow.Invitation;
}

function usePercentComplete(currentScreen) {
  const isSkipFindCommunity = useIsSkipFindCommunity();
  const { signupFlow } = useSelector(({ modal }) => modal.modalProps);
  let screens = Object.values(Screens);
  if (isSkipFindCommunity) {
    screens = screens.filter((screen) => screen !== Screens.FindCommunities);
  }
  if (signupFlow === SignupFlow.Invitation) {
    screens = screens.filter((s) => s !== Screens.CheckEmail && s !== Screens.FindCommunities && s !== Screens.Welcome);
  }
  const currentScreenNumber = screens.findIndex((screen) => screen === currentScreen) + 1;
  const totalScreensNumber = screens.length;
  return Math.floor(currentScreenNumber / totalScreensNumber * 100);
}

export function useFinishOnboarding(setCurrentScreen: (screen: Screens) => void) {
  const router = useRouter();
  const dispatch = useDispatch();
  const { signupFlow, redirectPage } = useSelector(({ modal }) => modal.modalProps);
  const isSignupFromPost = useSelector(({ pages }) => pages.singlePost.isSecondPageBlockMsgBoxVisible);

  return function finishOnboarding() {
    dispatch(fetchCurrentUser());
    // e.g. invitation page, etc
    if (redirectPage) {
      redirectPage();
    }
    // e.g. 2nd condition in the if: e.g. on confirm page if only following blog, etc
    else if (isSignupFromPost || signupFlow !== SignupFlow.Community && router.route !== '/') {
      setCurrentScreen(Screens.Welcome);
      trackFootfallEvent(Event.PROFILE_SURVEY, { clickType: 'viewed-welcome-modal' });
    } else {
      setCurrentScreen(Screens.Welcome);
      trackFootfallEvent(Event.PROFILE_SURVEY, { clickType: 'viewed-welcome-modal' });
      dispatch(setModalProps({ redirectOnClose: '/explore', signupFlow }));
    }
  };
}
