// @ts-strict-ignore
import { captureException } from '@sentry/nextjs';
import { setAlert } from 'src/components/alert/actions';
import actionTypes from 'src/modules/user/settings/actionTypes';
import contextActionTypes from 'src/modules/shared/context/actionTypes';
import api from 'src/utils/api';
import { UpdatePreferencesProps } from 'src/constants/types';

export const updatePreferences = (preference: UpdatePreferencesProps) => {
  return async (dispatch, getState) => {
    dispatch({ type: contextActionTypes.UPDATE_PREFERENCE });
    try {
      const state = getState();
      const userId = state.context.currentUser.userId;
      const response = await api.put(`private/users/${userId}/preferences`, preference);

      if (response?.data?.status?.success) {
        dispatch({ type: contextActionTypes.UPDATE_PREFERENCE_SUCCESS, preference });
        dispatch(setAlert('Success! Your account preference has been updated.', 'success'));
      } else {
        dispatch(setAlert());
      }
    } catch (ex) {
      dispatch(setAlert());
      captureException(ex);
      dispatch({ type: contextActionTypes.UPDATE_PREFERENCE_FAILURE });
    }
  };
};

export const updateEmail = (newEmail: string, signupParam = '') => {
  return async (dispatch, getState) => {
    dispatch({ type: contextActionTypes.UPDATE_EMAIL });
    try {
      const state = getState();
      const userId = state.context.currentUser.userId;
      const response = await api.put(`private/users/${userId}/account/email${signupParam}`, { email: newEmail });
      if (response?.data?.success) {
        dispatch({ type: contextActionTypes.UPDATE_EMAIL_SUCCESS, newEmail });
        dispatch(setAlert('Success! Your email has been updated.', 'success'));
      } else {
        dispatch(setAlert());
      }
    } catch (ex) {
      captureException(ex);
      dispatch({ type: contextActionTypes.UPDATE_EMAIL_FAILURE });
    }
  };
};

export const updateUsername = (newUsername: string) => {
  return async (dispatch, getState) => {
    dispatch({ type: contextActionTypes.UPDATE_USERNAME });
    try {
      const state = getState();
      const userId = state.context.currentUser.userId;
      const response = await api.put(`private/users/${userId}/account/username`, { newUsername });
      if (response?.data?.success) {
        dispatch({ type: contextActionTypes.UPDATE_USERNAME_SUCCESS, newUsername });
        dispatch(setAlert('Success! Your username has been updated.', 'success'));
      } else {
        dispatch(setAlert(`You changed your username twice already or username ${newUsername} was used recently.`, 'danger', 'none'));
      }
    } catch (ex) {
      captureException(ex);
      dispatch({ type: contextActionTypes.UPDATE_USERNAME_FAILURE });
    }
  };
};

export const updatePassword = ({ currentPassword, newPassword }: { currentPassword: string, newPassword: string }) => {
  return async (dispatch, getState) => {
    dispatch({ type: actionTypes.UPDATE_PASSWORD });
    try {
      const state = getState();
      const userId = state.context.currentUser.userId;
      const response = await api.put(`private/users/${userId}/account/password`, { currentPassword, newPassword });
      if (response?.data?.success) {
        dispatch({ type: actionTypes.UPDATE_PASSWORD_SUCCESS });
        dispatch(setAlert('Success! Your password has been updated.', 'success'));
      } else {
        dispatch(setAlert());
      }
    } catch (ex) {
      captureException(ex);
      dispatch({ type: actionTypes.UPDATE_PASSWORD_FAILURE });
    }
  };
};

export const deleteAccount = (reason?: string) => {
  return async (dispatch, getState) => {
    dispatch({ type: actionTypes.DELETE_ACCOUNT });
    try {
      const state = getState();
      const { userId } = state.context.currentUser;
      const response = await api.del(`private/users/${userId}/profile`, { params: { ...(!!reason?.length && { reasonDeleted: encodeURIComponent(reason) }) } });

      if (!response?.data?.status?.success) {
        dispatch(setAlert());
      } else {
        dispatch({ type: contextActionTypes.LOGOUT_SUCCESS });
      }
    } catch (ex) {
      captureException(ex);
      dispatch(setAlert());
      dispatch({ type: actionTypes.DELETE_ACCOUNT_FAILURE });
    }
  };
};
