// @ts-strict-ignore
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from 'src/utils/api';
import paginationResultsPerPage from 'src/constants/paginationResultsPerPage';
import { CommunityMembers, State } from 'src/constants/types';

export const initialState: CommunityMembers = {
  currentFilter: '',
  currentPage: 1,
  data: {
    members: [],
    total: 0,
  },
  isLoading: true,
};

export const communityMembersSlice = createSlice({
  name: 'communityMembers',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchMembers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchMembers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.currentFilter = action.payload.currentFilter;
        state.currentPage = action.payload.currentPage;
        state.data = action.payload.data;
      });
  },
});

export default communityMembersSlice.reducer;

type Data = { filter: string; page: number; }

export const fetchMembers = createAsyncThunk('communityMembers/fetchMembers', async (data: Data, thunkApi) => {
  try {
    const { filter: currentFilter, page: currentPage } = data;
    const store = thunkApi.getState() as State;
    const { context: { currentCommunity, currentUser } } = store;

    const params: { sorting?: string; start?: number; } = {};
    const limit = paginationResultsPerPage.searchMembers;
    const start = limit * (currentPage - 1);

    if (currentFilter) {
      params.sorting = currentFilter;
    }

    if (currentFilter === 'similar-to-me') {
      params['user-id'] = currentUser?.userId;
    }

    if (start > 0) {
      params.start = start;
    }

    const { data: { members, total } } = await api.get(`private/members/${currentCommunity?.slug}`, { params });
    const membersWithRoles = members.map((member) => ({ ...member, roles: member.roles.map((role) => role.role) }));
    return {
      data: { members: membersWithRoles, total },
      currentFilter: currentFilter || 'similar-to-me',
      currentPage: currentPage || 1,
    };
  } catch (err) {
    // A failed request or error in a thunk will never return a rejected promise.
    // As we want to log in the errors/reason/status of the failed request to Sentry,
    // we need to return a new value using the thunkAPI.rejectWithValue utility.
    return thunkApi.rejectWithValue(err);
  }
});
