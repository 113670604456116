import i18n from 'src/utils/translate';

export const setAlert = (
  message: string | JSX.Element = i18n.t('There was a problem. Please refresh the page and try again.'),
  type: 'success' | 'danger' = 'danger',
  timeout?: number | 'none',
) => {
  const defaultTimeout = timeout || type === 'danger' ? 'none' : 3000;
  return { type: 'ALERT_SET', alert: { message, type, timeout: defaultTimeout } };
};

export const unsetAlert = () => {
  return { type: 'ALERT_DISMISS' };
};
