import styled from 'styled-components';

type Props = {
  size?: Size;
  screenM?: Size;
  screenS?: Size;
  screenXS?: Size;
}
export enum Size {
  XL = '40px',
  L = '20px',
  M = '10px',
  S = '5px',
}

export default function Spacer({ size = Size.M, screenM, screenS, screenXS }: Props) {
  return (
    <Div
      $screenM={screenM || size}
      $screenS={screenS || size}
      $screenXS={screenXS || size}
      $size={size}
    />
  );
}

type DivProps = {
  $size: Size;
  $screenM: Size;
  $screenS: Size;
  $screenXS: Size;
}
const Div = styled.div<DivProps>`
  height: ${({ $size }) => $size};
  width: ${({ $size }) => $size};
  @media (${({ theme }) => theme.underScreenMedium}) {
    height: ${({ $screenM }) => $screenM};
    width: ${({ $screenM }) => $screenM};
  }
  @media (${({ theme }) => theme.underScreenSmall}) {
    height: ${({ $screenS }) => $screenS};
    width: ${({ $screenS }) => $screenS};
  }
  @media (${({ theme }) => theme.underScreenXsmall}) {
    height: ${({ $screenXS }) => $screenXS};
    width: ${({ $screenXS }) => $screenXS};
  }
`;
