// @ts-strict-ignore
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { subMonths } from 'date-fns/subMonths';
import { format } from 'date-fns/format';
import api, { createAuthenticatedApiInstance } from 'src/utils/api';
import { groupDataByReason, addStartAndEndDates, getFullSetOfDates, getValues } from 'src/modules/community/communityMetrics/moderation/moderationUtil';
import { Moderation } from 'src/modules/community/communityMetrics/types';
import { TableType } from 'src/modules/community/communityMetrics/moderation/pagination';
import paginationResultsPerPage from 'src/constants/paginationResultsPerPage';
import { State } from 'src/constants/types';

export const initialState: Moderation = {
  lastMonthDate: null,
  previousMonthDate: null,
  topPosters: {
    lastMonthData: {
      currentPage: 1,
      status: 'loading',
      users: [],
      total: 0,
    },
    previousMonthData: {
      currentPage: 1,
      status: 'loading',
      users: [],
      total: 0,
    },
  },
  topRepliers: {
    lastMonthData: {
      currentPage: 1,
      status: 'loading',
      users: [],
      total: 0,
    },
    previousMonthData: {
      currentPage: 1,
      status: 'loading',
      users: [],
      total: 0,
    },
  },
  contentReportReasons: {
    dates: [],
    reasons: [],
    status: 'loading',
    values: [],
  },
  authorsOfReportedContent: {
    lastMonthData: {
      currentPage: 1,
      status: 'loading',
      users: [],
      total: 0,
    },
    previousMonthData: {
      currentPage: 1,
      status: 'loading',
      users: [],
      total: 0,
    },
  },
  restrictedAndBanned: {
    dates: [],
    labels: [],
    status: 'loading',
    values: [],
  },
  membersWhoLeft: {
    dates: [],
    status: 'loading',
    values: [],
  },
};

export const moderationSlice = createSlice({
  name: 'moderation',
  initialState,
  reducers: {
    setLastMonthPage: (state, action) => {
      if (action.payload.tableType === TableType.Posters) {
        state.topPosters.lastMonthData.currentPage = action.payload.page;
      } else if (action.payload.tableType === TableType.Repliers) {
        state.topRepliers.lastMonthData.currentPage = action.payload.page;
      } else if (action.payload.tableType === TableType.Reported) {
        state.authorsOfReportedContent.lastMonthData.currentPage = action.payload.page;
      }
    },
    setPreviousMonthPage: (state, action) => {
      if (action.payload.tableType === TableType.Posters) {
        state.topPosters.previousMonthData.currentPage = action.payload.page;
      } else if (action.payload.tableType === TableType.Repliers) {
        state.topRepliers.previousMonthData.currentPage = action.payload.page;
      } else if (action.payload.tableType === TableType.Reported) {
        state.authorsOfReportedContent.previousMonthData.currentPage = action.payload.page;
      }
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchContentReportReasons.fulfilled, (state, action) => {
        const startDate = action.payload.meta?.startDate;
        const endDate = action.payload.meta?.endDate;
        state.contentReportReasons.status = 'succeeded';

        // Parsing data required because data returned by BE has no specific ordering and might not have 12 months of data
        const chartData = action.payload.rows as [string, number, string][] || [];
        const dataGroupedByReason = groupDataByReason(chartData);
        const reasons = dataGroupedByReason.map(reason => reason.reason);
        state.contentReportReasons.reasons = reasons;

        // Dates parsing
        const existingDates = chartData.map(array => {
          const date = array[0].split('-'); // to remove the day
          return new Date(`${date[0]}-${date[1]}`);
        });
        // Sort dates chronologically
        const sortedDates = existingDates.sort((a, b) => a.getTime() - b.getTime()).map(date => date.toISOString());
        const filteredDates = sortedDates.reduce((acc, current) => { // remove duplicates
          if (!acc.find(date => date === current)) acc.push(current);
          return acc;
        }, []);
        // Complete set of data from BE would have 72 results (6 reasons x 12 months).
        // If we don't have 72 results, we add the missing data
        if (chartData.length < 72 && startDate && endDate) {
          const completedDates = addStartAndEndDates(filteredDates, startDate, endDate);
          const newDates = getFullSetOfDates(completedDates);
          state.contentReportReasons.dates = newDates;
          const completeValues = getValues(dataGroupedByReason, newDates);
          state.contentReportReasons.values = completeValues;
        } else {
          const newDates = filteredDates.map(date => format(date, 'yyyy-MM-dd'));
          state.contentReportReasons.dates = newDates;
          const completeValues = getValues(dataGroupedByReason, newDates);
          state.contentReportReasons.values = completeValues;
        }
      })
      .addCase(fetchAuthorsOfReportedContent.fulfilled, (state, action) => {
        const isPreviousMonth = action.payload.isPreviousMonth;
        if (isPreviousMonth) {
          state.authorsOfReportedContent.previousMonthData.status = 'succeeded';
          state.authorsOfReportedContent.previousMonthData.currentPage = action.payload.currentPage;
          state.authorsOfReportedContent.previousMonthData.users = action.payload.users;
          state.authorsOfReportedContent.previousMonthData.total = action.payload.total;
        } else {
          state.authorsOfReportedContent.lastMonthData.status = 'succeeded';
          state.authorsOfReportedContent.lastMonthData.currentPage = action.payload.currentPage;
          state.authorsOfReportedContent.lastMonthData.users = action.payload.users;
          state.authorsOfReportedContent.lastMonthData.total = action.payload.total;
        }
      })
      .addCase(fetchTopPosters.fulfilled, (state, action) => {
        if (!state.lastMonthDate) {
          const endDate = action.payload.endDate;
          state.lastMonthDate = endDate;
          const prevDate = subMonths(new Date(endDate), 1);
          state.previousMonthDate = format(prevDate, 'yyyy-MM-dd');
        }

        const isPreviousMonth = action.payload.isPreviousMonth;
        if (isPreviousMonth) {
          state.topPosters.previousMonthData.status = 'succeeded';
          state.topPosters.previousMonthData.currentPage = action.payload.currentPage;
          state.topPosters.previousMonthData.users = action.payload.users;
          state.topPosters.previousMonthData.total = action.payload.total;
        } else {
          state.topPosters.lastMonthData.status = 'succeeded';
          state.topPosters.lastMonthData.currentPage = action.payload.currentPage;
          state.topPosters.lastMonthData.users = action.payload.users;
          state.topPosters.lastMonthData.total = action.payload.total;
        }
      })
      .addCase(fetchTopRepliers.fulfilled, (state, action) => {
        const isPreviousMonth = action.payload.isPreviousMonth;
        if (isPreviousMonth) {
          state.topRepliers.previousMonthData.status = 'succeeded';
          state.topRepliers.previousMonthData.currentPage = action.payload.currentPage;
          state.topRepliers.previousMonthData.users = action.payload.users;
          state.topRepliers.previousMonthData.total = action.payload.total;
        } else {
          state.topRepliers.lastMonthData.status = 'succeeded';
          state.topRepliers.lastMonthData.currentPage = action.payload.currentPage;
          state.topRepliers.lastMonthData.users = action.payload.users;
          state.topRepliers.lastMonthData.total = action.payload.total;
        }
      })
      .addCase(fetchRestrictedAndBanned.fulfilled, (state, action) => {
        state.restrictedAndBanned.status = 'succeeded';
        const chartData = action.payload.rows as [string, number, string][] || [];

        // restricted data are found in odd indexes in array
        const restrictedValues = chartData.filter((value, i) => i % 2 !== 0 && value).map(month => month[1]);
        // banned data are found in even indexes
        const bannedData = chartData.filter((value, i) => i % 2 === 0 && value);
        const bannedValues = bannedData.map(month => month[1]);
        const valuesData = bannedValues.length === 0 && restrictedValues.length === 0
          ? []
          : [restrictedValues, bannedValues];
        // Same dates for restricted and banned, so getting them from banned
        const dates = bannedData.map(month => month[0]);

        state.restrictedAndBanned.dates = dates;
        state.restrictedAndBanned.values = valuesData;
        state.restrictedAndBanned.labels = chartData.map(month => `${month[2]} members`).slice(0, 2).reverse();
      })
      .addCase(fetchMembersWhoLeft.fulfilled, (state, action) => {
        state.membersWhoLeft.status = 'succeeded';
        const chartData = action.payload.rows as [string, number][] || [];
        state.membersWhoLeft.dates = chartData.map(month => month[0]);
        state.membersWhoLeft.values = chartData.map(month => month[1]);
      });
  },
});

export const {
  setLastMonthPage,
  setPreviousMonthPage,
} = moderationSlice.actions;

export default moderationSlice.reducer;

export const fetchContentReportReasons = createAsyncThunk('moderation/fetchContentReportReasons', async (slug: string, thunkApi) => {
  try {
    const { data } = await api.get(`private/communities/${slug}/metrics/ReportOfAbuseReasonMonthly`);
    return data;
  } catch (err) {
    // A failed request or error in a thunk will never return a rejected promise.
    // As we want to log in the errors/reason/status of the failed request to Sentry,
    // we need to return a new value using the thunkAPI.rejectWithValue utility.
    return thunkApi.rejectWithValue(err);
  }
});

type Data = { slug: string; isPreviousMonth?: boolean; }

export const fetchAuthorsOfReportedContent = createAsyncThunk('moderation/fetchAuthorsOfReportedContent', async (data: Data, thunkApi) => {
  try {
    const store = thunkApi.getState() as State;
    const { authorsOfReportedContent: authors, previousMonthDate } = store.pages.communityMetrics.moderation;
    let currentPage = authors.lastMonthData.currentPage;
    if (data.isPreviousMonth) currentPage = authors.previousMonthData.currentPage;

    const params: { date?: string; limit?: number; start?: number; } = {};
    const limit = paginationResultsPerPage.communityMetricsTables;
    params.limit = limit;
    const start = limit * (currentPage - 1);
    if (start > 0) params.start = start;
    if (data.isPreviousMonth) params.date = previousMonthDate;

    const { data: { users, total } } = await api.get(`private/communities/${data.slug}/moderation/top/reported`, { params });
    return {
      currentPage: currentPage || 1,
      isPreviousMonth: data.isPreviousMonth,
      users,
      total,
    };
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

export const fetchTopPosters = createAsyncThunk('moderation/fetchTopPosters', async (data: Data, thunkApi) => {
  const api = createAuthenticatedApiInstance(thunkApi.getState);
  try {
    const store = thunkApi.getState() as State;
    const { topPosters, previousMonthDate } = store.pages.communityMetrics.moderation;
    let currentPage = topPosters.lastMonthData.currentPage;
    if (data.isPreviousMonth) currentPage = topPosters.previousMonthData.currentPage;

    const params: { date?: string; limit?: number; start?: number; } = {};
    const limit = paginationResultsPerPage.communityMetricsTables;
    params.limit = limit;
    const start = limit * (currentPage - 1);
    if (start > 0) params.start = start;
    if (data.isPreviousMonth) params.date = previousMonthDate;

    const { data: { users, total, meta: { endDate } } } = await api.get(`private/communities/${data.slug}/moderation/top/poster`, { params });
    return {
      currentPage: currentPage || 1,
      endDate,
      isPreviousMonth: data.isPreviousMonth,
      users,
      total: total > 15 ? 15 : total, // we show max 15 items
    };
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

export const fetchTopRepliers = createAsyncThunk('moderation/fetchTopRepliers', async (data: Data, thunkApi) => {
  try {
    const store = thunkApi.getState() as State;
    const { topRepliers, previousMonthDate } = store.pages.communityMetrics.moderation;
    let currentPage = topRepliers.lastMonthData.currentPage;
    if (data.isPreviousMonth) currentPage = topRepliers.previousMonthData.currentPage;

    const params: { date?: string; limit?: number; start?: number; } = {};
    const limit = paginationResultsPerPage.communityMetricsTables;
    params.limit = limit;
    const start = limit * (currentPage - 1);
    if (start > 0) params.start = start;
    if (data.isPreviousMonth) params.date = previousMonthDate;

    const { data: { users, total } } = await api.get(`private/communities/${data.slug}/moderation/top/replier`, { params });
    return {
      currentPage: currentPage || 1,
      isPreviousMonth: data.isPreviousMonth,
      users,
      total: total > 15 ? 15 : total, // we show max 15 items
    };
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

export const fetchRestrictedAndBanned = createAsyncThunk('moderation/fetchRestrictedAndBanned', async (slug: string, thunkApi) => {
  try {
    const { data } = await api.get(`private/communities/${slug}/metrics/RestrictedBannedMembersMonthly`);
    return data;
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});

export const fetchMembersWhoLeft = createAsyncThunk('moderation/fetchMembersWhoLeft', async (slug: string, thunkApi) => {
  try {
    const { data } = await api.get(`private/communities/${slug}/metrics/LostMembersMonthly`);
    return data;
  } catch (err) {
    return thunkApi.rejectWithValue(err);
  }
});
