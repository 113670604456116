import styled from 'styled-components';
import Icon from 'src/components/icon';
import { titleMedium, titleSmall, titleXsmall, textSmallLight } from 'styles-js/mixins/typography';
import { Container as EmptyState } from 'src/modules/community/communityMetrics/emptyState';

export const Metric = styled.div`
  @media print {
    break-inside: avoid;
  }
`;

export const Title = styled.h2`
  ${titleMedium}
  margin: 40px 0 15px;
  padding-bottom: 10px;
  border-bottom: ${({ theme }) => theme.greyLine};
  @media (${({ theme }) => theme.underScreenMedium}) {
    margin: 30px 0 10px;
  }
  @media print {
    ${titleSmall}
    margin: 30px 0 10px;
  }
`;

export const SubTitle = styled.h3`
  ${titleSmall}
  margin-bottom: 20px;
  @media (${({ theme }) => theme.underScreenMedium}) {
    margin: 15px;
  }
  @media (${({ theme }) => theme.underScreenSmall}) {
    margin: 0 0 15px;
  }
  @media print {
    ${titleXsmall}
    margin: 15px 15px 5px;
  }
`;

export const Content = styled.div`
  display: flex;
  column-gap: 30px;
  @media (${({ theme }) => theme.underScreenMedium}) {
    flex-direction: column;
  }
  @media print {
    flex-direction: row;
    column-gap: 20px;
  }
`;

export const Main = styled.div`
  width: 75%;
  @media (${({ theme }) => theme.underScreenMedium}) {
    width: auto;
  }
  @media print {
    width: 75%;
  }
`;

export const ChartContainer = styled.div<{ $noBreakInsideOnPrint?: boolean }>`
  padding: 25px;
  border: ${({ theme }) => theme.greyLine};
  @media (${({ theme }) => theme.underScreenMedium}) {
    padding: 0;
  }
  @media (${({ theme }) => theme.underScreenSmall}) {
    border: none;
    border-bottom: ${({ theme }) => theme.greyLine};
  }
  @media print {
    padding: 0;
    border: ${({ theme }) => theme.greyLine};
    ${props => props.$noBreakInsideOnPrint && `
      min-height: 240px;
      break-inside: avoid;
    `};
  }
  & + & {
    margin-top: 15px;
    @media print {
      margin-top: 10px;
    }
  }
`;

export const CanvasContainer = styled.div`
  position: relative;
  width: 100%;
  height: 350px;
  @media (${({ theme }) => theme.underScreenMedium}) {
    height: 300px;
  }
  @media print {
    min-height: 240px;
    height: 240px;
    canvas {
      max-width: 100%;
      min-height: 100%;
      max-height: 100%;
      height: auto !important;
      width: auto !important;
    }
  }
`;

export const DoughnutContainer = styled.div`
  display: flex;
  align-items: center;
  @media (${({ theme }) => theme.underScreenMedium}) {
    margin-bottom: 25px;
  }
  @media (${({ theme }) => theme.underScreenXsmall}) {
    flex-direction: column-reverse;
    gap: 20px;
  }
  @media print {
    flex-direction: row;
    gap: 0;
    margin: 10px 0 15px;
  }
`;

export const DoughnutCanvasContainer = styled.div`
  position: relative;
  width: 50%;
  height: 260px;
  @media (${({ theme }) => theme.underScreenMedium}) {
    height: 260px;
  }
  @media (${({ theme }) => theme.underScreenXsmall}) {
    width: 100%;
    height: 200px;
  }
  @media print {
    width: 50%;
    min-height: 170px;
    height: 170px;
    canvas {
      max-width: 100%;
      min-height: 100%;
      max-height: 100%;
      height: auto !important;
      width: auto !important;
    }
  }
`;

export const LegendContainer = styled.div`
  width: 50%;
  padding: 0 6% 0 10%;
  @media (${({ theme }) => theme.underScreenSmall}) {
    padding: 0 6%;
  }
  @media (${({ theme }) => theme.underScreenXsmall}) {
    width: 100%;
  }
  @media print {
    width: 50%;
    padding: 0 6%;
  }

  ul {
    margin: 0;
    padding: 0;
  }
  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  .text-container {
    display: flex;
    align-items: center;
  }
  .color {
    flex-shrink: 0;
  }
  .text {
    ${textSmallLight};
    margin-left: 10px;
    @media print {
      font-size: 12px;
      line-height: 15px;
    }
  }
  .percentage {
    ${titleXsmall};
    font-size: ${({ theme }) => theme.fontSizeXs};
    padding-left: 5px;
    @media print {
      font-size: 12px;
      line-height: 15px;
    }
  }
`;

export const SideBar = styled.div`
  width: 25%;
  padding-top: 20px;
  @media (${({ theme }) => theme.underScreenMedium}) {
    width: auto;
  }
  @media print {
    width: 25%;
    padding-top: 20px;
    font-size: 12px;
    line-height: 15px;
  }
  p + p {
    margin-top: 10px;
  }
`;

export const MoreInfoBox = styled.div`
  padding: 15px;
  margin-top: 15px;
  background-color: ${({ theme }) => theme.colorBlueBackground};
  @media print {
    display: none;
  }
`;

export const BoldText = styled.p`
  font-weight: ${({ theme }) => theme.fontWeightBold};
`;

export const InfoIcon = styled(Icon)`
  padding: 5px;
  :before {
    transform: rotate(180deg);
  }
`;

export const TableContent = styled.div`
  padding: 25px;
  border: ${({ theme }) => theme.greyLine};
  @media (${({ theme }) => theme.underScreenMedium}) {
    padding: 0;
  }
  @media (${({ theme }) => theme.underScreenSmall}) {
    border: none;
    border-bottom: ${({ theme }) => theme.greyLine};
  }
  @media print {
    padding: 0;
    border: ${({ theme }) => theme.greyLine};
  }
  & + & {
    margin-top: 15px;
    @media print {
      margin-top: 10px;
    }
  }
`;

export const TableWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 25px;
  @media (${({ theme }) => theme.underScreenMedium}) {
    padding: 0 15px 10px;
  }
  @media (${({ theme }) => theme.underScreenSmall}) {
    flex-direction: column;
    gap: 15px;
    padding: 0;
  }
  @media print {
    flex-direction: row;
    padding: 10px 15px 0;
    gap: 15px;
  }
  ${EmptyState} {
    width: 100%;
    @media (${({ theme }) => theme.underScreenMedium}) {
      margin: 30px 0;
    }
    @media print {
      font-size: 12px;
      line-height: 15px;
    }
  }
`;

export const Table = styled.table`
  width: 50%;
  @media (${({ theme }) => theme.underScreenSmall}) {
    width: 100%;
  }
  @media print {
    font-size: 12px;
    line-height: 15px;
  }

  caption {
    caption-side: top;
    padding: 0 0 10px;
    color: ${({ theme }) => theme.colorTextBlack};
    @media print {
      ${titleXsmall}
    }
  }
  thead {
    background-color: ${({ theme }) => theme.colorBlueBackground};
  }
  thead tr, tbody tr {
    border: ${({ theme }) => theme.greyLine};
  }
  th,
  tbody td {
    padding: 8px 10px;
  }
  th:nth-child(even), td:nth-child(even), tfoot {
    text-align: right;
  }
  tfoot td {
    padding: 15px 0 5px;
  }
  ul { // Pagination
    margin: 0;
    li:last-child {
      padding-right: 0;
    }
    @media print {
      display: none;
    }
  }
`;

export const EmptyStateContainer = styled.div`
  width: 50%;
  @media (${({ theme }) => theme.underScreenSmall}) {
    width: 100%;
  }
  @media print {
    font-size: 12px;
    line-height: 15px;
  }
  div {
    @media (${({ theme }) => theme.underScreenMedium}) {
      margin: 30px 0;
    }
  }
`;

export const LoadingState = styled.div`
  display: flex;
  justify-content: center;
  height: 350px;
  @media (${({ theme }) => theme.underScreenMedium}) {
    height: 300px;
  }
`;
