import { useEffect } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'src/store/store';
import i18n from 'src/utils/translate';
import FindCommunityCard from 'src/modules/shared/modal/components/signup/onboardingScreens/findCommunityCard';
import FindCommunitySearchBar from 'src/modules/shared/modal/components/signup/onboardingScreens/findCommunitySearchBar';
import Button from 'src/components/buttons/button';
import { fetchCommunities, userFollowingBlog } from 'src/modules/shared/modal/components/signup/actions';
import { Screens, useFinishOnboarding } from 'src/modules/shared/modal/components/signup/onboardingModal';
import Loader from 'src/components/loader';
import Spacer, { Size } from 'src/components/spacer';
import { Body, Footer } from 'src/components/modals/modal';
import { P } from 'src/modules/shared/modal/components/signup/onboardingScreens/sharedStyles';
import { ModalTitle } from 'styles-js/modals';

type Props = {
  setCurrentScreen: (screen: Screens) => void;
}
export default function FindCommunities({ setCurrentScreen }: Props) {
  const isLoading = useSelector(({ modals }) => modals.signupOnboarding.isLoading) ?? true;
  const followedCommunities = useSelector(({ modals }) => modals.signupOnboarding?.followedCommunities);
  const communities = useSelector(({ modals }) => modals.signupOnboarding?.communities);
  useRecommendedCommunities();
  const isFollowingMoreThanBlog = useIsFollowingMoreThanBlog();
  const finishOnboarding = useFinishOnboarding(setCurrentScreen);
  useUserFollowingBlog();

  return (
    <>
      <Body>
        <ModalTitle>{i18n.t('Find your communities')}</ModalTitle>
        <P>{followedCommunities?.length}{' '}{i18n.t('selected')}</P>
        <div data-testid="signup-followed-communities">
          {followedCommunities?.map(({ followerCount, logoUrl, name, slug }) => (
            <FindCommunityCard
              followerCount={followerCount}
              isFollowed={true}
              key={slug}
              logoUrl={logoUrl}
              name={name}
              slug={slug}
            />
          ))}
        </div>
        {isLoading && <StyledLoader />}
        <Spacer size={Size.L} />
        <FindCommunitySearchBar
          label={<label>{i18n.t('Search for your interests')}</label>}
          placeholder={i18n.t('e.g. conditions, health interests')}
        />
        <Spacer size={Size.L} />
        <div data-testid="signup-suggested-communities">
          {communities?.map(({ followerCount, slug, logoUrl, name }) => (
            <FindCommunityCard
              followerCount={followerCount}
              isFollowed={false}
              isHidden={followedCommunities?.some((c) => c.slug === slug)}
              key={slug}
              logoUrl={logoUrl}
              name={name}
              slug={slug}
            />
          ))}
        </div>
      </Body>
      <Footer>
        <Button
          dataTestId="find-communities-continue"
          disabled={!isFollowingMoreThanBlog}
          onClick={finishOnboarding}
        >
          {isFollowingMoreThanBlog ? i18n.t('Continue') : i18n.t('Join one more to continue')}
        </Button>
      </Footer>
    </>
  );
}

const StyledLoader = styled(Loader)`
  height: 45px;
`;

export function useRecommendedCommunities() {
  const dispatch = useDispatch();
  const signupOnboarding = useSelector(({ modals }) => modals.signupOnboarding);

  useEffect(() => {
    if (!signupOnboarding?.isNoResults && signupOnboarding?.communities?.length === 0) {
      dispatch(fetchCommunities());
    }
  }, [signupOnboarding?.isNoResults, signupOnboarding?.communities, dispatch]);
}

function useIsFollowingMoreThanBlog() {
  const followedCommunities = useSelector(({ modals }) => modals.signupOnboarding.followedCommunities);
  return followedCommunities?.some(({ slug }) => slug !== 'blog');
}

function useUserFollowingBlog() {
  // If user is already following blog, then we need to update the follow list for this screen
  const dispatch = useDispatch();
  const isFollowingBlog = useSelector(({ context }) => context.currentUser?.followedCommunities?.some(({ slug }) => slug === 'blog'));
  const isBlogInFollowedCommunities = useSelector(({ modals }) => modals.signupOnboarding?.followedCommunities?.some(({ slug }) => slug === 'blog'));
  const isLoading = useSelector(({ modals }) => modals.signupOnboarding.isLoading);

  useEffect(() => {
    if (isLoading) return;
    if (isFollowingBlog && !isBlogInFollowedCommunities) {
      dispatch(userFollowingBlog());
    }
  }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps
}
