import { useRouter } from 'next/router';
import styled from 'styled-components';
import MultiplierLinks from 'src/components/pagination/mutiplierLinks';
import NextPageLink from 'src/components/pagination/nextPageLink';
import PageLinks from 'src/components/pagination/pageLinks';
import { textLinkPrimary } from 'styles-js/mixins/typography';

const maxNbPages = 10;
const jumpMultiplier = 10;
const leapMultiplier = 100;

type Props = {
  lastPageOverride?: number,
  pageSize?: number,
  isShowBotLinks?: boolean,
  total: number,
};
export default function Pagination({
  lastPageOverride = 500,
  pageSize = 30,
  isShowBotLinks = false,
  total = 0,
}: Props) {
  const router = useRouter();
  const page = Number(router.query?.page) || 1;

  if (total === 0) return null;

  return (
    <>
      <Ul>
        <PageLinks
          lastPageOverride={lastPageOverride}
          page={page}
          pageSize={pageSize}
          total={total}
        />
        <NextPageLink
          lastPageOverride={lastPageOverride}
          page={page}
          pageSize={pageSize}
          total={total}
        />
      </Ul>
      <MultiplierLinks
        isShowBotLinks={isShowBotLinks}
        lastPageOverride={lastPageOverride}
        limit={0}
        maxNbPages={maxNbPages}
        multiplier={jumpMultiplier}
        page={page}
        pageSize={pageSize}
        total={total}
      />
      <MultiplierLinks
        isShowBotLinks={isShowBotLinks}
        lastPageOverride={lastPageOverride}
        limit={2}
        maxNbPages={maxNbPages}
        multiplier={leapMultiplier}
        page={page}
        pageSize={pageSize}
        total={total}
      />
    </>
  );
}

export const Ul = styled.ul`
  margin: 20px 3px;
  padding: 0;

  li {
    padding: 7px 12px;
    display: inline-block;
    
    &:not(:last-child) {
      border-right: ${({ theme }) => theme.greyLine};
    }

    a {
      ${textLinkPrimary}
      text-decoration: none;
      color: ${({ theme }) => theme.colorBlue};
    }

    &.dots {
      color: ${({ theme }) => theme.colorGrey};
    }

    &.active {
      color: ${({ theme }) => theme.colorBlack};
    }
  }
`;
