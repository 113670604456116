import { Event } from 'src/constants/footfall';
import { trackFootfallEvent } from 'src/utils/footfall';

export function ffNewChatStart(section: 'inbox' | 'profile' | 'community-about' | 'group-participants') {
  trackFootfallEvent(Event.CLICKED, { clickType: 'chat-new-start', clickMetadata: { section } });
}

export function ffNewChatEnd(section: 'inbox' | 'profile' | 'community-about' | 'group-participants') {
  trackFootfallEvent(Event.CLICKED, { clickType: 'chat-new-end', clickMetadata: { section } });
}

export function ffFirstGroupChat() {
  trackFootfallEvent(Event.CLICKED, { clickType: 'chat-new-group' });
}

export function ffChatActionStart(section: 'inbox' | 'thread', action: 'archive' | 'mute' | 'leave') {
  trackFootfallEvent(Event.CLICKED, { clickType: 'chat-action-start', clickMetadata: { section, action } });
}

export function ffChatActionEnd(section: 'inbox' | 'thread', action: 'archive' | 'mute' | 'leave') {
  trackFootfallEvent(Event.CLICKED, { clickType: 'chat-action-end', clickMetadata: { section, action } });
}

export function ffChatPrivacyAlert(action: 'learn-more' | 'yes' | 'no') {
  trackFootfallEvent(Event.CLICKED, { clickType: 'chat-privacy', clickMetadata: { action } });
}

export function ffChatUnverified(action: 'resend' | 'update') {
  trackFootfallEvent(Event.CLICKED, { clickType: 'chat-unverified', clickMetadata: { action } });
}
