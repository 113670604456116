// @ts-strict-ignore
import { css } from 'styled-components';

export const FadeTop = props => css`
  ::after {
    content: '';
    position: absolute;
    bottom: -50px;
    width: 100%;
    height: 50px;
    background: linear-gradient(to bottom, ${props.theme.colorWhite80}, hsla(0,0%,100%,0));
    z-index: 1;
    pointer-events: none;

    @media (max-width: ${props.theme.screenXsMin}) {
      bottom: -30px;
      height: 30px;
    }
  }
`;

export const FadeBottom = props => css`
  ::before {
    content: '';
    position: absolute;
    top: -50px;
    right: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background: linear-gradient(to top, ${props.theme.colorWhite80}, hsla(0,0%,100%,0));
    z-index: 1;
    pointer-events: none;

    @media (max-width: ${props.theme.screenXsMin}) {
      top: -30px;
      height: 30px;
    }
  }
`;

export const fadeEllipsis = props => css`
  background: linear-gradient(90deg,hsla(0,0%,100%,0),${props.theme.colorWhite} 85%);
  bottom: 0;
  content: "";
  height: 21px;
  pointer-events: none;
  position: absolute;
  right: 0;
  text-align: right;
  width: 40%;
`;
