// @ts-strict-ignore
import cookie from 'cookie';
import { setServerSession, unsetServerSession } from 'src/modules/shared/auth/actions';
import { fetchCurrentUser, setIsAndroidUser } from 'src/modules/shared/context/actions';
import { getUserAgent } from 'src/utils/userAgent';

export async function setSession({ req, store: { dispatch, getState } }) {
  if (!req) return;

  const { huSessID } = cookie.parse(req.headers.cookie || '');

  await dispatch(setServerSession(huSessID));
  const state = getState();

  if (!state.context?.sessionId?.endsWith(':00000000-0000-0000-0000-000000000000')) {
    await dispatch(fetchCurrentUser());
  }

  await dispatch(setIsAndroidUser(getUserAgent(req).includes('Android')));
}

export async function clearSessionId({ req, store: { dispatch } }) {
  if (!req) return;
  await dispatch(unsetServerSession());
}
